import axios from "axios";
import { takeEvery, put, takeLatest } from "redux-saga/effects";
import {
  Product_,
  Product_List,
  Product_By_Subcategory,
  Product_By_Category,
  Set_Product_List,
  Set_Product_,
  Product_Search,
  Filter,
  Add_To_Cart,
  Set_Cart_list
} from "../redux/constant";

function* getProducts() {
  let data = yield fetch(
    process.env.REACT_APP_API_PRODUCT_LIST
  );
  data = yield data.json();

  yield put({ type: Set_Product_List, data: data.product_list });
}

function* productsBySubcategory(data) {
  // let result = yield fetch(`https://fakestoreapi.com/products/category/${data.query}`);
  // result = yield result.json();
  // console.log("result in search", data)
  // yield put({ type: Set_Product_List, data: result })

  try {
    const res = yield axios.post(
      process.env.REACT_APP_API_SHOP_BY_SUBCATEGORY,
      {
        sub_category_id: data.id,
      }
    );
    yield put({ type: Set_Product_List, data: res.data.product_list });
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* productsByCategory(data) {
  // let result = yield fetch(`https://fakestoreapi.com/products/category/${data.query}`);
  // result = yield result.json();
  // console.log("result in search", data)
  // yield put({ type: Set_Product_List, data: result })

  try {
    const res = yield axios.post(
      process.env.REACT_APP_API_SHOP_BY_CATEGORY,
      {
        category_id: data.id,
      }
    );
    yield put({ type: Set_Product_List, data: res.data.product_list });
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}




function* productSearch(data) {
  // let result = yield fetch(`https://fakestoreapi.com/products/category/${data.query}`);
  // result = yield result.json();
  // console.log("result in search", data)
  // yield put({ type: Set_Product_List, data: result })

  try {
    const res = yield axios.post(
      process.env.REACT_APP_API_PRODUCT_SEARCH,
      {
        product_name: data.query,
      }
    );
    yield put({ type: Set_Product_List, data: res.data.product_list });
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}



function* product(data) {
  // let item = yield fetch(`https://fakestoreapi.com/products/${data.query}`);
  // item = yield item.json();
  // console.log("result in search",data)
  // yield put ({type: Set_Product_,data:item })
  try {
    const res = yield axios.post(
      "https://kaeinyonne.com/admin/api/single-product-list",
      {
        product_id: data.query,
      }
    );
    yield put({ type: Set_Product_, data: res.data.product_list[0] });
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* productFilterItems(data) {

  try {
    const res = yield axios.post(
      "https://kaeinyonne.com/admin/api/product-by-filter",
      {
        value: data.query,
        category_id: data.id
      }
    );
    yield put({ type: Set_Product_List, data: res.data.list });
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}


function* setCartItems() {

  try {
    const res = yield axios(
      process.env.REACT_APP_API_CART_LIST, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem("test_token")
        )}`,

        "Content-Type": "application/json",
      },
    }

    );
    yield put({ type: Set_Cart_list, data: res.data.cart_list });
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}



function* productSaga() {
  yield takeEvery(Product_List, getProducts);
  yield takeEvery(Product_By_Subcategory, productsBySubcategory);
  yield takeEvery(Product_By_Category, productsByCategory);
  yield takeEvery(Product_Search, productSearch);
  yield takeLatest(Product_, product);
  yield takeEvery(Filter, productFilterItems)
  yield takeLatest(Add_To_Cart, setCartItems)
}

export default productSaga;
