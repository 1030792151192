import React, { useState, useEffect } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import ProductsCarousel from "../home/ProductsCarousel";
import { addToCart } from "../../redux/action";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";
import { toast } from "react-custom-alert";
import { product } from "../../redux/productAction";
import { useParams } from "react-router-dom";
import Img from "../../assets/kcholce.png";
import ReactPlayer from "react-player";
import { AiFillPlayCircle } from "react-icons/ai";
import "../../App.css";
import ReactStars from "react-stars";
import { useNavigate } from "react-router-dom";
import ReactImageMagnify from "react-image-magnify";
import Bestsellers from "../home/Bestsellers";
import Banner3 from "../banner/Banner3";
import { calculatPercentage } from "../../utils/calculatPercentage";

const Product = () => {
  const [progress, setProgress] = useState(0);
  const [quantity, setquantity] = useState(1);

  const navigat = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  let imgUrl = "https://kaeinyonne.com/admin/product_image/";

  // console.log(params.slug);

  useEffect(() => {
    dispatch(product(params.slug));
  }, [dispatch, params]);

  const data = useSelector((state) => state.productItem);

  // console.log("fafafafafafa", data);

  const result = useSelector((state) => state.cartData);

  let sellerid = data.length && data.map((item) => +item.seller_id);

  const [showDescription, setShowDescription] = useState(true);
  const [reviews, setReviews] = useState(false);

  const [videoM, setvideoM] = useState(false);
  const [size, setsize] = useState([]);
  const [color, setcolor] = useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    dispatch(product(params));
  }, [params, dispatch]);

  const [ratingproduct, setratingproduct] = useState();

  useEffect(() => {
    try {
      axios("https://kaeinyonne.com/admin/api/single-product-list", {
        method: "POST",
        data: {
          product_id: params,
        },
      })
        .then((res) => {
          setratingproduct(res.data.rating);
          setsize(res.data.product_size);
          setcolor(res.data.product_color);
        })
        .catch((error) => { });
    } catch (err) {
      toast.error("Something Wrong");
    }
  }, [params]);

  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const handleSizeClick = (size) => {
    setSelectedSize(size);
    // Perform any other actions you need when a size is clicked
  };

  const handleColorClick = (size) => {
    setSelectedColor(size);
    // Perform any other actions you need when a size is clicked
  };

  const [gstPercentage, setGstPercentage] = useState(null);

  useEffect(() => {
    // Set GST percentage when data changes
    if (data && data.length > 0) {
      setGstPercentage(data[0].product_gst);
    }
  }, [data]); // Only run this effect when data changes

  // console.log(data);
  const calculateGST = (price, gstPercentage) => {
    const gstAmount = (price * gstPercentage) / 100;
    const totalAmountGst = price + gstAmount;
    return totalAmountGst;
  };

  let gstAmount;

  if (data && data.length > 0 && data[0].sale_price !== undefined) {
    gstAmount = calculateGST(+data[0].sale_price, gstPercentage);
  } else {
    // console.log("rr");
  }

  // console.log(gstAmount);

  // Display the GST amount in the console
  // console.log("data", gstPercentage, data[0].sale_price);
  // console.log("GST Amount:", gstAmount);
  // console.log("uuuuu", gstPercentage);

  // add to cart

  const handleCart = async (e) => {
    if (!localStorage.getItem("test_token")) {
      navigat("/login");
    }

    setProgress(10);

    try {
      await axios(process.env.REACT_APP_API_ADD_TO_CART, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("test_token")
          )}`,

          "Content-Type": "application/json",
        },
        data: {
          product_id: e.id,
          quantity: quantity,
          price: e.sale_price,
          discount: e.product_discount,
          name: e.product_name,
          shipping_cost: 0,
          color: selectedColor,
          size: selectedSize,
          seller_id: +sellerid.toString(),
          total_price_with_gst: quantity > 1 ? gstAmount * quantity : gstAmount,
          gst_price: (gstAmount - +e.sale_price) * quantity,
        },
      })
        .then((res) => {
          setProgress(75);
          toast.success(res.data.message);
          dispatch(addToCart());

          setProgress(100);
          // window.location.reload();
        })
        .catch((error) => {
          if (error.response) {
            toast.warning(" Please login");

            setProgress(100);
          } else if (error.request) {
            toast.error(error.request);
            setProgress(100);
          } else {
            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      toast.error("Something Wrong");
    }
  };
  const handleDecrement = () => {
    if (quantity > 1) {
      setquantity((prevCount) => prevCount - 1);
    }
  };

  const handleIncrement = () => {
    if (quantity < 10) {
      setquantity((prevCount) => prevCount + 1);
    }
  };

  console.log(data,"ddddddd");

  let productid = data.length && data.map((item) => +item.id);
  let productcategory = data.length && data.map((item) => +item.category_id);
  let subcategoryID =  data.length && data.map((item) => +item.subcategory_id);
  
  let productPrice = data.length && data.map((item) => +item.sale_price);

  let totalPrice;

  if (data && data.length > 0 && data[0].sale_price_gst !== undefined) {
    totalPrice = data[0].sale_price_gst;
  } else {
    // Handle the case where data[0] or sale_price_gst is undefined
    // console.log("tt"); // or another suitable default value
  }

  // Now you can safely use totalPrice

  // console.log("totalPrice yy", data[0]);

  const [checkout, setcheckout] = useState(false);
  const [name, setName] = useState();
  const [number, setnumber] = useState();
  const [address, setAddress] = useState();
  const [city, setcity] = useState();
  const [pinCord, setpinCord] = useState();
  const [state, setstate] = useState();
  const [country, setcountry] = useState();
  const [type, settype] = useState("Home");
  const [paymentType, setpaymentType] = useState("online");
  const [userDeliveryAddress, setUserDeliveryAddress] = useState([]);
  const [deliveryId, setDeliveryId] = useState();

  let userid =
    userDeliveryAddress.length && userDeliveryAddress.map((item) => +item.id);

  const qtyapi = Array.from(String(quantity), Number);

  const chakeOutPay = () => {
    if (!localStorage.getItem("test_token")) {
      navigat("/login");
    }
    setcheckout(!checkout);
  };

  const [nameErr, setnameErr] = useState(false);
  const [phoneErr, setphoneErr] = useState(false);
  const [addressErr, setaddressErr] = useState(false);

  //userName_Vault
  const nameHandler = (e) => {
    let item = e.target.value;
    const isValidName = /^[a-zA-Z]+ [a-zA-Z]/;

    if (isValidName.test(item)) {
      setnameErr(false);
      setName(item);
    } else {
      setnameErr(true);
    }
  };

  //userPhone_Vault
  const phoneHandler = (e) => {
    let item = e.target.value;
    if (item.length < 10) {
      setphoneErr(true);
    } else {
      setphoneErr(false);
    }
    setnumber(item);
  };

  const addressHandler = (e) => {
    let item = e.target.value;
    const isValidName = /^[a-zA-Z]+ [a-zA-Z]/;

    if (isValidName.test(item)) {
      setaddressErr(false);
      setAddress(item);
    } else {
      setaddressErr(true);
    }
  };

  const handleSaveBtn = async (e) => {
    setProgress(10);
    try {
      setProgress(75);
      e.preventDefault();
      await axios(process.env.REACT_APP_API_ADD_DELIVERY_ADDRESS, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("test_token")
          )}`,

          "Content-Type": "application/json",
        },
        data: {
          contact_person_name: name,
          address_type: type,
          address: address,
          city: city,
          pin_code: pinCord,
          phone: number,
          state: state,
          country: country,
        },
      })
        .then((res) => {
          setProgress(100);

          toast.success(res.data.message);

          //navigat('/userprofile');
          //window.location.reload(false);
          //window.location.href = "http://localhost:3000/userprofile"
        })
        .catch((error) => {
          if (error.response) {
            toast.warning(error.response.data.message);

            setProgress(100);
          } else if (error.request) {
            toast.error(error.request);
            setProgress(100);
          } else {
            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      setProgress(100);
      toast.error("Something Wrong");
    }
  };

  useEffect(() => {
    const userDeliveryAddress = async () => {
      setProgress(10);
      try {
        await axios(process.env.REACT_APP_API_ADD_USER_DELIVERY_ADDRESS, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("test_token")
            )}`,

            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            setProgress(75);

            setUserDeliveryAddress(res.data.address);

            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {
              toast.warning(" Please login");

              setProgress(100);
            } else if (error.request) {
              toast.error(error.request);
              setProgress(100);
            } else {
              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    userDeliveryAddress();
  }, [checkout]);

  const handleDelete = async (e) => {
    try {
      setProgress(75);
      await axios(process.env.REACT_APP_API_ADD_DELIVERY_ADDRESS_DELETE, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("test_token")
          )}`,

          "Content-Type": "application/json",
        },
        data: {
          delivery_address_id: e,
        },
      })
        .then((res) => {
          setProgress(100);

          toast.success(res.data.message);

          //navigat('/userprofile');
          //window.location.reload(false);
          //window.location.href = "http://localhost:3000/userprofile"
        })
        .catch((error) => {
          if (error.response) {
            toast.warning(error.response.data.message);

            setProgress(100);
          } else if (error.request) {
            toast.error(error.request);
            setProgress(100);
          } else {
            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      setProgress(100);
      toast.error("Something Wrong");
    }
  };

  const handleDeliveryId = (e) => {
    setDeliveryId(e);
  };

  if (totalPrice !== undefined && totalPrice !== null) {
    var amountOnline = +totalPrice.toString();
    // Now you can use amountOnline
  } else {
    // console.log("totalPrice is undefined or null.");
  }

  let ammountQty = amountOnline * qtyapi.toString();

  // let totalAmountGst = ammountQty + ammountQty * 0.18

  // console.log("uiiiijjhh", totalAmountGst)

  const sizeArray = [];
  const colorArray = [];
  const gstArrayAmount = [];
  const deliveryCharge = [];

  if (selectedSize != null) {
    sizeArray.push(selectedSize);
  } else {
    sizeArray.push("null");
  }

  if (selectedColor != null) {
    colorArray.push(selectedColor);
  } else {
    colorArray.push("null");
  }

  if (ammountQty <= 449) {
    deliveryCharge.push(49);
  } else {
    deliveryCharge.push(0);
  }

  gstArrayAmount.push(gstAmount);
  // console.log("ggggggggggaaaaa", gstArrayAmount);

  const [hasShownWarningForCod, setHasShownWarningForCod] = useState(false);
  const [hasShownWarningForWallet, setHasShownWarningForWallet] =
    useState(false);

  useEffect(() => {
    if (paymentType === "cod" && !hasShownWarningForCod) {
      toast.warning("cod products can not be returned");
      setHasShownWarningForCod(true);
      setHasShownWarningForWallet(false); // Reset warning for wallet
    } else if (paymentType === "wallet" && !hasShownWarningForWallet) {
      toast.warning("wallet products can not be returned");
      setHasShownWarningForWallet(true);
      setHasShownWarningForCod(false); // Reset warning for COD
    }
  }, [paymentType, hasShownWarningForCod, hasShownWarningForWallet]);

  function formatArrayAsCustomString(arr) {
    return '[' + arr.join(', ') + ']';
  }

  const [dataInf, setDataInf] = useState()
  useEffect(() => {
    const getInf = async () => {
      try {
        const response = await axios("https://kaeinyonne.com/admin/api/check-purchase-premium-service-deliveryCharge", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("test_token")
            )}`,

            "Content-Type": "application/json",
          },
        })
        // console.log("kkkkkrr", response.data)
        //const trackingUrl = response.data
        setDataInf(response.data.delivery_charge)

      } catch (error) {
        console.error('Error:', error.message);
        // toast.error('Something went wrong');
      }
    };
    getInf()
  }, [])

  // console.log("dataaa", dataInf)

  const handlePay = async () => {
    // console.log("Payment Type:", JSON.stringify(colorArray), JSON.stringify(sizeArray));
    // console.log("Payment Type:", formatArrayAsCustomString(colorArray), formatArrayAsCustomString(sizeArray),);

    switch (paymentType) {
      case "cod":
        try {
          setProgress(75);
          axios(process.env.REACT_APP_API_ORDER, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("test_token")
              )}`,

              "Content-Type": "application/json",
            },
            data: {
              product_id: JSON.stringify(productid),
              quantity: JSON.stringify(qtyapi),
              product_price: JSON.stringify(productPrice),
              delivery_address_id:
                JSON.stringify(+deliveryId.toString()) ||
                JSON.stringify(+userid[0].toString()),
              payment_mode: paymentType,
              //order_id: response.razorpay_order_id,
              payment_id: "",
              total_price: dataInf === 'true' ? (ammountQty <= 449 ? ammountQty + 49 : ammountQty) : ammountQty,
              seller_id: JSON.stringify(sellerid),
              product_color: formatArrayAsCustomString(colorArray),
              product_size: formatArrayAsCustomString(sizeArray),
              delivery_charge: dataInf === 'true' ? (JSON.stringify(deliveryCharge)) : JSON.stringify([0]),
              total_price_with_gst: JSON.stringify(gstArrayAmount),
            },
          })
            .then((res) => {
              setProgress(100);

              toast.success(res.data.message);
              //window.location.reload();
            })
            .catch((error) => {
              if (error.response) {
                toast.warning(error.response.data.message);

                setProgress(100);
              } else if (error.request) {
                toast.error(error.request);
                setProgress(100);
              } else {
                toast.error(error.message);
                setProgress(100);
              }
            });
        } catch (err) {
          toast.error("Something Wrong");
        }

        break;

      case "online":
        try {
          setProgress(75);
          axios(process.env.REACT_APP_API_ORDER, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("test_token")
              )}`,

              "Content-Type": "application/json",
            },
            data: {
              product_id: JSON.stringify(productid),
              quantity: JSON.stringify(qtyapi),
              product_price: JSON.stringify(productPrice),
              delivery_address_id:
                JSON.stringify(+deliveryId.toString()) ||
                JSON.stringify(+userid[0].toString()),
              payment_mode: paymentType,
              //order_id: response.razorpay_order_id,
              payment_id: "",
              total_price: dataInf === 'true' ? (ammountQty <= 449 ? ammountQty + 49 : ammountQty) : ammountQty,
              seller_id: JSON.stringify(sellerid),
              product_color: formatArrayAsCustomString(colorArray),
              product_size: formatArrayAsCustomString(sizeArray),
              delivery_charge: dataInf === 'true' ? (JSON.stringify(deliveryCharge)) : JSON.stringify([0]),
              total_price_with_gst: JSON.stringify(gstArrayAmount),
            },
          })
            .then((res) => {
              setProgress(100);
              const url = res.data.key;
              // console.log("url", url);
              // console.log("URL to Open:", url);
              window.open(url, "_blank");
            })
            .catch((error) => {
              if (error.response) {
                toast.warning(error.response.data.message);
              } else if (error.request) {
                toast.error("No response received from the server");
              } else {
                toast.error(error.message);
              }
              setProgress(100);
            });
        } catch (err) {
          toast.error("Something went wrong");
        }
        // var rzp = window.Razorpay(options);
        // rzp.open();
        break;

      case "wallet":
        try {
          setProgress(75);
          axios(process.env.REACT_APP_API_ORDER, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("test_token")
              )}`,

              "Content-Type": "application/json",
            },
            data: {
              product_id: JSON.stringify(productid),
              quantity: JSON.stringify(qtyapi),
              product_price: JSON.stringify(productPrice),
              delivery_address_id:
                JSON.stringify(+deliveryId.toString()) ||
                JSON.stringify(+userid[0].toString()),
              payment_mode: paymentType,
              //order_id: response.razorpay_order_id,
              payment_id: "",
              total_price: dataInf === 'true' ? (ammountQty <= 449 ? ammountQty + 49 : ammountQty) : ammountQty,
              seller_id: JSON.stringify(sellerid),
              product_color: formatArrayAsCustomString(colorArray),
              product_size: formatArrayAsCustomString(sizeArray),
              delivery_charge: dataInf === 'true' ? (JSON.stringify(deliveryCharge)) : JSON.stringify([0]),
              total_price_with_gst: JSON.stringify(gstArrayAmount),
            },
          })
            .then((res) => {
              setProgress(100);

              toast.success(res.data.message);
              window.location.reload();
            })
            .catch((error) => {
              if (error.response) {
                toast.warning(error.response.data.message);

                setProgress(100);
              } else if (error.request) {
                toast.error(error.request);
                setProgress(100);
              } else {
                toast.error(error.message);
                setProgress(100);
              }
            });
        } catch (err) {
          toast.error("Something Wrong");
        }

        break;
      default:
      // code block
    }
    setcheckout(!checkout);
  };

  let url = "https://kaeinyonne.com/admin/product_multi_image/";

  const imgS =
    data.length &&
    data.map((item) => item.productimage.map((img) => img.product_multi_image));

  let im = imgS[0];

  const [changeImg, setchangeImg] = useState(0);

  // useEffect(() => {
  //   Update the document title using the browser API
  //   document.title = `You clicked ${changeImg} times`;
  // });

  const hoverHandler = (item, i) => {
    setchangeImg(i);
  };

  const [reviewList, setreviewList] = useState();
  const [rno, setrno] = useState();

  useEffect(() => {
    const userReviewLise = () => {
      try {
        axios("https://kaeinyonne.com/admin/api/single-product-review-list", {
          method: "POST",
          data: {
            product_id: params,
          },
        })
          .then((res) => {
            // setProgress(75);
            // toast.success(res.data.message);
            // dispatch(addToCart(e));
            // setProgress(100);
            setrno(res.data.total_no);
            setreviewList(res.data);
          })
          .catch((error) => {
            // if (error.response) {
            //   // status code out of the range of 2xx
            //   console.log("Data :", error.response.data);
            //   toast.warning(error.response.data.message);
            //   console.log("Status :" + error.response.status);
            //   setProgress(100);
            // } else if (error.request) {
            //   // The request was made but no response was received
            //   console.log(error.request);
            //   toast.error(error.request);
            //   setProgress(100);
            // } else {
            //   // Error on setting up the request
            //   console.log("Error", error.message);
            //   toast.error(error.message);
            //   setProgress(100);
            // }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    userReviewLise();
  }, [reviews, params]);

  const [brand, setbrand] = useState();

  useEffect(() => {
    try {
      axios("https://kaeinyonne.com/admin/api/brand-name", {
        method: "POST",
        data: {
          brand_id: data[0].brand_id,
        },
      })
        .then((res) => {
          // console.log("yyyyttttddddrrr", res.data);
          setbrand(res.data.brand_list);
        })
        .catch((error) => { });
    } catch (err) {
      toast.error("Something Wrong");
    }
  }, [data]);

  // console.log("yyy", brand)

  var currentDate = new Date();

  var endDateString = data[0].kaeinyonne_choice_end
  var endDate = new Date(endDateString);

  // console.log("yyyyyyyyyysssssssssssspppppppppppp", currentDate, "uuuuuuuuuuuu", endDate)

  // console.log("ggggggggggggggtttttttttttt", currentDate < endDate)

  return (
    <div>
      <LoadingBar
        color="#FF8000"
        progress={progress}
        height={2}
        onLoaderFinished={() => setProgress(0)}
      />
      <section className="py-4 sm:py-4 min-h-screen">
        <div className="container mx-auto px-4">
          {data &&
            data.map((item) => (
              <div
                key={item.id}
                className="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16"
              >
                <div className="lg:col-span-3 lg:row-end-1">
                  <div className="lg:flex lg:items-start">
                    <div className=" items-center lg:order-2 lg:ml-5 h-2/4 w-full lg:w-2/4">
                      <div className=" relative flex overflow-hidden rounded-lg">
                        <ReactImageMagnify
                          {...{
                            smallImage: {
                              alt: "Wristwatch by Ted Baker London",
                              isFluidWidth: true,
                              src: url + im[changeImg],
                            },
                            largeImage: {
                              src: url + im[changeImg],
                              width: 1200,
                              height: 1800,
                            },
                          }}
                        />
                        {
                          item.kaeinyonne_choice === '1' && currentDate < endDate && (
                            <div className="absolute top-1 right-1 w-[100px] rounded-md inline-flex items-center p-2">
                              <img src={Img} alt="" />
                            </div>
                          )
                        }


                      </div>
                    </div>

                    <div className="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                      <div className="flex flex-row items-start lg:flex-col">
                        {data.map((item) =>
                          item.productimage.map((img, i) => (
                            <div
                              key={img.id}
                              type="button"
                              className={`flex-0  aspect-square mb-3 mr-3 h-20 overflow-hidden rounded-lg  cursor-pointer ${i === changeImg
                                ? "border-2 border-gray-900"
                                : ""
                                }  text-center`}
                              onMouseOver={() =>
                                hoverHandler(img.product_multi_image, i)
                              }
                            >
                              <img
                                className="p-1 rounded-lg"
                                src={url + img.product_multi_image}
                                alt=""
                              />
                            </div>
                          ))
                        )}
                        <button
                          onClick={() => setvideoM(!videoM)}
                          type="button"
                          className="flex-0 relative  aspect-square mb-3 h-20 overflow-hidden rounded-lg text-center"
                        >
                          <img
                            className="p-1 opacity-30"
                            src={`${imgUrl}${item.product_image}`}
                            alt=""
                          />
                          <span className="absolute bottom-0 play_btn">
                            <AiFillPlayCircle
                              size={20}
                              className=" text-[#217CD1]"
                            />
                          </span>
                        </button>
                        {/* <button
                        type="button"
                        className="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 border-transparent text-center"
                      >
                        <img
                          className="p-1"
                          src={`${imgUrl}${item.product_image}`}
                          alt=""
                        />
                      </button>
                      <button
                        type="button"
                        className="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 border-transparent text-center"
                      >
                        <img
                          className="p-1"
                          src={`${imgUrl}${item.product_image}`}
                          alt=""
                        />
                      </button> */}
                      </div>
                    </div>
                  </div>
                </div>
                {videoM && (
                  <div
                    className="relative z-10"
                    aria-labelledby="modal-title"
                    role="dialog"
                    aria-modal="true"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start ">
                              <ReactPlayer
                                className="react-player"
                                width="100%"
                                height="250px"
                                controls={true}
                                playing={true}
                                url={item.product_video}
                              />
                            </div>
                          </div>
                          <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            {/* <button type="button" className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button> */}
                            <button
                              onClick={() => setvideoM(!videoM)}
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                  <h1 className="sm: text-2xl font-bold text-gray-900 sm:text-3xl">
                    {item.product_name}
                  </h1>

                  <p className="text-body text-sm lg:text-base mt-3 leading-6 text-gray-900">
                    {item.small_description}
                  </p>

                  {/* <div className="mt-5 flex items-center">
                  <div className="absolute  inline-flex items-center rounded-lg bg-white p-2">
                    <samp className="text-yellow-400">
                      <AiFillStar />
                    </samp>
                    <span className="text-slate-900 ml-1 text-md">5</span>
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500">
                    500 Reviews
                  </p>
                </div> */}
                  <div className="p-2 flex flex-col items-center justify-between space-y-4  py-4 sm:flex-row sm:space-y-0">
                    <div>
                      <p className="space-x-2">
                        <span className="text-2xl font-semibold">
                          ₹{item.sale_price_gst}
                        </span>
                        <span className="text-sm line-through text-gray-500">
                          ₹{item.product_price}
                        </span>
                        <span className="text-sm text-red-700">
                          {/* {(
                            (100 * (item.product_price - item.sale_price_gst)) /
                            item.product_price
                          ).toFixed(2)} */}
                          {calculatPercentage(item.product_price, item.sale_price_gst)}
                          % off
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="pb-3 border-b border-gray-300 text-gray-900">
                    <div className="mb-4">
                      {color.length === 0 || size.length === 0 ? (
                        ""
                      ) : (
                        <h3 className="text-base md:text-lg text-heading font-semibold mb-2.5 capitalize">
                          Available colors & sizes
                        </h3>
                      )}

                      <div className="product-color-size-sec">
                        <div className="product-color-sec">
                          {color.map((variant) => (
                            <div
                              key={variant.id}
                              className={`product-color-block ${selectedColor === variant.size ? "selected" : ""
                                }`}
                              style={{
                                backgroundColor: `${variant.size}`,
                              }}
                              onClick={() => handleColorClick(variant.size)}
                            ></div>
                          ))}
                        </div>
                        <div className="product-size-sec cursor-pointer">
                          {size.map((variant) => (
                            <div
                              key={variant.id}
                              className={`product-size ${selectedSize === variant.size ? "selected" : ""
                                }`}
                              onClick={() => handleSizeClick(variant.size)}
                            >
                              {variant.size}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {(item.sale_price <= 449 && dataInf === "true") && (
                      <h3 className="text-base md:text-lg text-heading font-semibold mb-2.5 capitalize">
                        ₹
                        {(item.sale_price <= 449 && dataInf === "true")
                          ? `49/- delivery charges will apply if the total amount is not greater that ₹449/-`
                          : ""}
                      </h3>
                    )}

                    {/* {gstPercentage === null ? "" : <h3 className="text-base md:text-lg text-heading font-semibold mb-2.5 capitalize">
                    GST {item.product_gst}%
                  </h3>} */}

                    <h3 className="text-base md:text-lg text-heading font-semibold mb-2.5 capitalize">
                      Rating
                    </h3>
                    <div className="flex">
                      <ReactStars
                        count={5}
                        value={ratingproduct}
                        edit={false}
                        size={25}
                        color2={"#facc15"}
                      />
                    </div>
                    {data[0].brand_id === null ? (
                      ""
                    ) : (
                      <>
                        <h3 className=" mt-2 text-base md:text-lg text-heading font-semibold mb-2.5 capitalize">
                          brand
                        </h3>
                        <h3 className=" text-sm md:text-base text-heading font-semibold mb-2.5 capitalize">
                          {brand && brand.name}
                        </h3>
                      </>
                    )}
                  </div>
                  <div className="flex gap-2 items-center space-s-4 md:pr-32 lg:pr-12 2xl:pr-32 3xl:pr-48 border-b text-gray-900 border-gray-300 py-8">
                    <div className="group flex items-center justify-between rounded-md overflow-hidden flex-shrink-0 border h-11 md:h-12 border-gray-300">
                      <button
                        disabled={
                          result.find(
                            (cartItem) => +cartItem.product_id === item.id
                          )
                            ? true
                            : false
                        }
                        onClick={handleDecrement}
                        className="flex items-center justify-center flex-shrink-0 h-full transition ease-in-out duration-300 focus:outline-none w-10 md:w-12 text-heading border-e border-gray-300 hover:text-white hover:bg-heading"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          className="h-3 w-3"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 12h-15"
                          ></path>
                        </svg>
                      </button>
                      <span className="font-semibold flex items-center justify-center h-full transition-colors duration-250 ease-in-out cursor-default flex-shrink-0 text-base text-heading w-12 md:w-12 xl:w-12">
                        {quantity}
                      </span>
                      <button
                        disabled={
                          result.find(
                            (cartItem) => +cartItem.product_id === item.id
                          )
                            ? true
                            : false
                        }
                        onClick={handleIncrement}
                        className=" flex flex-wrap  items-center justify-center h-full flex-shrink-0 transition ease-in-out duration-300 focus:outline-none w-10 md:w-12 text-heading border-s border-gray-300 hover:text-white hover:bg-heading"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          className="h-3 w-3"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                          ></path>
                        </svg>
                      </button>
                    </div>
                    {color.length === 0 || size.length === 0 ? (
                      <button
                        disabled={
                          result.find(
                            (cartItem) => +cartItem.product_id === item.id
                          )
                            ? true
                            : false
                        }
                        onClick={(e) => handleCart(item)}
                        className={`flex items-center justify-center px-2 py-3.5 ${result.find(
                          (cartItem) => +cartItem.product_id === item.id
                        )
                          ? "bg-gray-600"
                          : "bg-[#88C645] hover:bg-[#F79946]"
                          } text-center text-sm text-white rounded duration-300`}
                      >
                        <AiOutlineShoppingCart size={16} />
                        <samp className="ml-2">Add to Cart</samp>
                      </button>
                    ) : (
                      <button
                        disabled={result.find(
                          (cartItem) => +cartItem.product_id === item.id
                        )}
                        onClick={(e) => {
                          if (
                            colorArray[0] === "null" ||
                            sizeArray[0] === "null"
                          ) {
                            toast.warning(
                              "Please select color and size before adding to cart."
                            );
                          } else {
                            handleCart(item);
                          }
                        }}
                        className={`flex items-center justify-center px-2 py-3.5 ${result.find(
                          (cartItem) => +cartItem.product_id === item.id
                        )
                          ? "bg-gray-600"
                          : "bg-[#88C645] hover:bg-[#F79946]"
                          } text-center text-sm text-white rounded duration-300`}
                      >
                        <AiOutlineShoppingCart size={16} />
                        <samp className="ml-2">Add to Cart</samp>
                      </button>
                    )}

                    <button
                      onClick={() => {
                        if (
                          colorArray[0] === "null" ||
                          sizeArray[0] === "null"
                        ) {
                          toast.warning(
                            "Please select color and size before Buy."
                          );
                        } else {
                          chakeOutPay();
                        }
                      }}
                      className={`rounded  bg-[#164574] hover:bg-[#0a64be] px-2 py-3.5 text-center text-sm flex items-center justify-center text-white `}
                    >
                      Buy now
                    </button>
                  </div>
                  {/* <div className="flex ml-2">
                  <button
                    disabled={
                      result.find((cartItem) => cartItem.id === item.id)
                        ? true
                        : false
                    }
                    onClick={(e) => handleCart(item)}
                    className={`flex items-center justify-center px-3 py-2 ${result.find((cartItem) => cartItem.id === item.id)
                      ? "bg-gray-600"
                      : "bg-[#88C645] hover:bg-[#F79946]"
                      } text-center text-sm text-white rounded duration-300`}
                  >
                    <AiOutlineShoppingCart size={16} />
                    <samp className="ml-2">Add to Cart</samp>
                  </button>
                  <button className="px-3 py-1 ml-2 bg-[#88C645] hover:bg-[#F79946] text-center text-sm text-white rounded duration-300">
                    Buy Now
                  </button>
                </div> */}
                  {/* <h2 className="mt-8 text-lg text-gray-900">Available offers</h2>
                <ul className=" ml-12 ">
                  <ul className="space-y-2">
                    <li className="flex items-center text-left text-sm font-medium text-gray-700">
                      <span className="mr-9 text-sm font-bold  align-middle text-green-600">
                        <MdLocalOffer size={20} />
                      </span>
                      Delivered To Your Door
                    </li>
                    <li className="flex items-center text-left text-sm font-medium text-gray-700">
                      <span className="mr-9 text-sm font-bold  align-middle text-green-600">
                        <MdLocalOffer size={20} />
                      </span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </li>
                    <li className="flex items-center text-left text-sm font-medium text-gray-700">
                      <span className="mr-9 text-sm font-bold  align-middle text-green-600">
                        <MdLocalOffer size={20} />
                      </span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </li>
                    <li className="flex items-center text-left text-sm font-medium text-gray-700">
                      <span className="mr-9 text-sm font-bold  align-middle text-green-600">
                        <MdLocalOffer size={20} />
                      </span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </li>
                  </ul>
                </ul> */}
                  <ul className=" ml-12 mt-3">
                    <li></li>
                  </ul>
                  {/* <ul className="mt-8 space-y-2">
                  <li className="flex items-center text-left text-sm font-medium text-gray-600">
                    <svg
                      className="mr-2 block h-5 w-5 align-middle text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        className=""
                      ></path>
                    </svg>
                    Free shipping worldwide
                  </li>

                  <li className="flex items-center text-left text-sm font-medium text-gray-600">
                    <svg
                      className="mr-2 block h-5 w-5 align-middle text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                        className=""
                      ></path>
                    </svg>
                    Cancel Anytime
                  </li>
                </ul> */}
                  {/* <p className=" mt-5" >{item.small_description}</p> */}

                  <div className="py-6 text-gray-900">
                    <ul className="text-sm space-y-5 pb-1">
                      {/* <li>
                      <span className="font-semibold text-heading inline-block pr-2">
                        SKU:
                      </span>
                      N/A
                    </li>
                    <li>
                      <span className="font-semibold text-heading inline-block pr-2">
                        Category:
                      </span>
                      <a
                        className="transition hover:underline hover:text-heading"
                        href="#"
                      >
                        {}
                      </a>
                    </li> */}
                      {/* <li className="productTags">
                      <span className="font-semibold text-heading inline-block pr-2">
                        Tags:
                      </span>
                      <a
                        className="inline-block pr-1.5 transition hover:underline hover:text-heading last:pr-0"
                        href="#"
                      >
                        {item.tags}
                        <span className="text-heading">,</span>
                      </a>
                    </li> */}
                    </ul>
                  </div>
                </div>

                <div className="lg:col-span-3">
                  <div className="">
                    <nav className="flex gap-4">
                      <button
                        onClick={() => {
                          setShowDescription(!showDescription);
                          setReviews(!reviews);
                        }}
                        title=""
                        className=" hover:border-gray-400 hover:text-gray-800"
                      >
                        {" "}
                        Description{" "}
                      </button>

                      <button
                        onClick={() => {
                          setReviews(!reviews);
                          setShowDescription(!showDescription);
                        }}
                        title=""
                        className="inline-flex items-center border-b-2 border-transparent py-4 text-sm font-medium text-gray-600"
                      >
                        Reviews
                        <span className="ml-2 block rounded-full bg-gray-500 px-2 py-px text-xs font-bold text-gray-100">
                          {" "}
                          {rno}{" "}
                        </span>
                      </button>
                    </nav>
                  </div>

                  {showDescription && (
                    <div className="mt-8 flow-root sm:mt-12 mb-6">
                      <h1 className="text-3xl font-bold">Product Details</h1>
                      <p
                        className="mt-4"
                        dangerouslySetInnerHTML={{ __html: item.product_desc }}
                      />
                    </div>
                  )}

                  {reviews && (
                    <>
                      <div className="mb-6">
                        {/* <div className="my-10 mx-auto max-w-screen-md px-16 py-16">
                        <div className="flex w-full flex-col">
                          <div className="flex flex-col sm:flex-row">
                            <div className="my-4 rounded-xl bg-white py-2 px-2 shadow sm:my-0 sm:ml-auto">
                              <div className="flex h-8 items-center text-2xl font-bold text-blue-900">
                                <span className="h-6 w-6 text-yellow-400">
                                  <AiFillStar />
                                </span>
                                <span className=" ml-2">

                                  {ratingproduct}
                                </span>
                              </div>
                              <p className="text-sm text-gray-500">
                                Average User Rating
                              </p>
                            </div>
                          </div>
                          <div className="text-gray-700">
                            <p className="font-medium">Reviews</p>
                            <ul className="mb-6 mt-2 space-y-2">
                              <li className="flex items-center text-sm font-medium">
                                <span className="w-3">5</span>
                                <span className="mr-4 text-yellow-400">
                                  <span className="h-5 w-5">
                                    <AiFillStar size={18} />
                                  </span>
                                </span>
                                <div className="mr-4 h-2 w-96 overflow-hidden rounded-full bg-gray-300">
                                  <div className="h-full w-10/12 bg-yellow-400"></div>
                                </div>
                                <span className="w-3">56</span>
                              </li>
                              <li className="flex items-center text-sm font-medium">
                                <span className="w-3">4</span>{" "}
                                <span className="mr-4 text-yellow-400">
                                  <span className="h-5 w-5">
                                    <AiFillStar size={18} />
                                  </span>
                                </span>
                                <div className="mr-4 h-2 w-96 overflow-hidden rounded-full bg-gray-300">
                                  <div className="h-full w-8/12 bg-yellow-400"></div>
                                </div>
                                <span className="w-3">12</span>
                              </li>
                              <li className="flex items-center text-sm font-medium">
                                <span className="w-3">3</span>{" "}
                                <span className="mr-4 text-yellow-400">
                                  <span className="h-5 w-5">
                                    <AiFillStar size={18} />
                                  </span>
                                </span>
                                <div className="mr-4 h-2 w-96 overflow-hidden rounded-full bg-gray-300">
                                  <div className="h-full w-1/12 bg-yellow-400"></div>
                                </div>
                                <span className="w-3">4</span>
                              </li>
                              <li className="flex items-center text-sm font-medium">
                                <span className="w-3">2</span>{" "}
                                <span className="mr-4 text-yellow-400">
                                  <span className="h-5 w-5">
                                    <AiFillStar size={18} />
                                  </span>
                                </span>
                                <div className="mr-4 h-2 w-96 overflow-hidden rounded-full bg-gray-300">
                                  <div className="h-full w-0 bg-yellow-400"></div>
                                </div>
                                <span className="w-3">0</span>
                              </li>
                              <li className="flex items-center text-sm font-medium">
                                <span className="w-3">1</span>{" "}
                                <span className="mr-4 text-yellow-400">
                                  <span className="h-5 w-5">
                                    <AiFillStar size={18} />
                                  </span>
                                </span>
                                <div className="mr-4 h-2 w-96 overflow-hidden rounded-full bg-gray-300">
                                  <div className="h-full w-1/12 bg-yellow-400"></div>
                                </div>
                                <span className="w-3">5</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div> */}

                        {/* <h2 className="text-xl ml-4 font-bold sm:text-2xl inline-flex">
                        Customer Reviews
                      </h2>
                      <button
                        onClick={() => setgetReview(!getReview)}
                        className="bg-gray-300 ml-3 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                      >
                        <AiOutlineEdit />
                        <span className="ml-2">Write/Edit</span>
                      </button> */}
                        {/* {getReview && (
                        <div
                          className="relative z-10"
                          aria-labelledby="modal-title"
                          role="dialog"
                        >
                          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                          <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                  <div className=" sm:items-start">
                                    <div className="mt-6">
                                      <div className="mb-6">
                                        <textarea
                                          onChange={(e) =>
                                            setReview(e.target.value)
                                          }
                                          rows="10"
                                          name="message"
                                          placeholder="Your Message"
                                          className="text-body-color bg-white rounded border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-indigo-200 border-[f0f0f0] focus:border-primary w-full border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                                        ></textarea>
                                      </div>
                                      <div className=" ml-2">
                                        <p>Rate us</p>
                                        <ReactStars
                                          count={5}
                                          onChange={ratingChanged}
                                          size={30}
                                          edit={true}
                                          half={false}
                                          color2={"#facc15"}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="bg-gray-50 px-4 lg:w-fill py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                  <button
                                    onClick={() => setgetReview(!getReview)}
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    //disabled={!rating || !review}
                                    onClick={userReview}
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}
                        <ul className="mt-3">
                          {reviewList.list.map((item) => (
                            <li
                              key={item.id}
                              className="py-1 text-left border rounded-lg px-2 m-1"
                            >
                              <div className="flex items-start justify-between">
                                <div className=" flex ">
                                  <img
                                    className="block h-10 w-10 max-w-full flex-shrink-0 rounded-full align-middle"
                                    src={
                                      "https://kaeinyonne.com/admin/profile_image/" +
                                      item.profile_image
                                    }
                                    alt=""
                                  />

                                  <div className="ml-6">
                                    <div className="flex items-center">
                                      <ReactStars
                                        count={5}
                                        value={item.rating}
                                        edit={false}
                                        size={25}
                                        color2={"#facc15"}
                                      />
                                    </div>
                                    <p className="mt-3 text-base text-gray-900">
                                      {item.review}
                                    </p>
                                    <p className="mt-2 text-sm font-bold text-gray-900">
                                      {item.user_name}
                                    </p>
                                    {/* <p className="mt-1 text-sm text-gray-600">
                                    {item.date-time}
                                  </p> */}
                                  </div>
                                </div>
                                {/* <div className={` cursor-pointer ${item.user_id === item.user.id ? "inline" : "hidden"} `} onClick={() => setgetReview(!getReview)} ><AiOutlineEdit /></div> */}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
        <Banner3 />
        {checkout && (
          <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-6 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="">
                      {userDeliveryAddress.length === 0 ? (
                        <div className="mx-auto max-w-lg px-4 lg:px-8">
                          <form className="grid grid-cols-6 gap-4">
                            <div className="col-span-3">
                              <label className="block text-xs font-medium text-gray-700 dark:text-gray-900">
                                Full Name
                              </label>
                              <input
                                onChange={nameHandler}
                                type="text"
                                value={name}
                                placeholder="Full Name"
                                className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400"
                              />
                              {nameErr ? (
                                <label className=" text-red-500 ml-3">
                                  Enter a valid name
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-span-3">
                              <label className="block text-xs font-medium text-gray-700 dark:text-gray-900">
                                Phone
                              </label>
                              <input
                                onChange={phoneHandler}
                                type="number"
                                id="number"
                                value={number}
                                placeholder="Phone Number"
                                className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400 "
                              />
                              {phoneErr ? (
                                <label className=" text-red-500 ml-3">
                                  Enter a valid name
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-span-6">
                              <label className="block text-xs font-medium text-gray-700 dark:text-gray-900">
                                Address
                              </label>
                              <input
                                onChange={addressHandler}
                                type="text"
                                id="add"
                                value={address}
                                placeholder="Address"
                                className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400"
                              />
                              {addressErr ? (
                                <label className=" text-red-500 ml-3">
                                  Enter a valid name
                                </label>
                              ) : (
                                ""
                              )}
                            </div>

                            <fieldset className="col-span-6">
                              <div className="mt-1 space-y-2 rounded-md shadow-sm">
                                <div className="flex space-x-2">
                                  <div className="flex-1">
                                    <label htmlFor="City" className="sr-only">
                                      City
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(e) => setcity(e.target.value)}
                                      placeholder="City"
                                      value={city}
                                      className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400 "
                                    />
                                  </div>
                                  <div className="flex-1">
                                    <label
                                      htmlFor="PinCord"
                                      className="sr-only"
                                    >
                                      PinCord
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        setpinCord(e.target.value)
                                      }
                                      placeholder="Pin Code"
                                      value={pinCord}
                                      className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400 "
                                    />
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <fieldset className="col-span-6">
                              <div className="mt-1 space-y-2 rounded-md shadow-sm">
                                <div className="flex space-x-2">
                                  <div className="flex-1">
                                    <label htmlFor="Ctate" className="sr-only">
                                      State
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(e) => setstate(e.target.value)}
                                      placeholder="State"
                                      value={state}
                                      className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400 "
                                    />
                                  </div>
                                  <div className="flex-1">
                                    <label
                                      htmlFor="Country"
                                      className="sr-only"
                                    >
                                      Country
                                    </label>
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        setcountry(e.target.value)
                                      }
                                      placeholder="Country"
                                      value={country}
                                      className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400"
                                    />
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <fieldset className="col-span-6">
                              <legend className="block text-sm font-medium text-gray-700">
                                Address Type
                              </legend>
                              <div className="mt-1 space-y-2 rounded-md shadow-sm">
                                <div>
                                  <select
                                    id="Country"
                                    onChange={(e) => settype(e.target.value)}
                                    className="mt-1  flex h-10 w-full rounded-md border border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400"
                                  >
                                    <option>Home</option>
                                    <option>Office</option>
                                    {/* <option>Scotland</option>
                                <option>France</option>
                                <option>Belgium</option>
                                <option>Japan</option> */}
                                  </select>
                                </div>
                              </div>

                              <button
                                disabled={
                                  !name ||
                                  !type ||
                                  !address ||
                                  !city ||
                                  !pinCord ||
                                  !number ||
                                  !state ||
                                  !country
                                }
                                onClick={handleSaveBtn}
                                className={`rounded-md ${!name ||
                                  !type ||
                                  !address ||
                                  !city ||
                                  !pinCord ||
                                  !number ||
                                  !state ||
                                  !country
                                  ? "cursor-no-drop bg-gray-500 hover:bg-gray-700"
                                  : "cursor-pointer bg-green-500 hover:bg-green-700"
                                  } px-3.5 py-1.5 mt-3 w-full text-base font-semibold leading-7 text-white `}
                              >
                                save
                              </button>
                            </fieldset>
                          </form>
                        </div>
                      ) : (
                        <>
                          {userDeliveryAddress.map((add) => (
                            <div
                              key={add.id}
                              className="flex mb-4 items-center pl-4 border border-gray-200 rounded dark:border-gray-700"
                            >
                              <div className="flex items-center h-20">
                                <input
                                  id="helper-radio"
                                  aria-describedby="helper-radio-text"
                                  type="radio"
                                  value=""
                                  checked={deliveryId === add.id ? true : false}
                                  onClick={(e) => handleDeliveryId(add.id)}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 border rounded-full"
                                />
                              </div>
                              <div className="ml-2 text-sm relative">
                                <label
                                  htmlFor="helper-radio"
                                  className=" text-lg font-bold text-gray-900 "
                                >
                                  {add.address_type}
                                </label>
                                <p
                                  id="helper-radio-text"
                                  className="  text-base  font-medium text-gray-900 "
                                >
                                  {add.address}, {add.city}, {add.state},{" "}
                                  {add.pin_code}, {add.country}
                                </p>
                                <button
                                  onClick={(e) => handleDelete(add.id)}
                                  className=" text-black absolute   md:left-96  top-1 left-56"
                                >
                                  <MdDelete
                                    size={20}
                                    className="text-red-500 hover:text-red-800"
                                  />
                                </button>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      //disabled={userDeliveryAddress.length === 0 ? true : false}
                      onClick={handlePay}
                      type="button"
                      className="inline-flex my-2 md:my-0 w-full items-center  justify-center rounded-md bg-[#88C645] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#4e7228] sm:ml-3 sm:w-auto"
                    >
                      Continue
                    </button>
                    <button
                      onClick={() => setcheckout(!checkout)}
                      type="button"
                      className="my-2 md:my-0 items-center inline-flex w-full justify-center rounded-md bg-white px-3 py-2   text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                    <div className=" space-y-2 rounded-md shadow-sm md:mr-3 ">
                      <div>
                        <select
                          id="Country"
                          onChange={(e) => setpaymentType(e.target.value)}
                          className="mt-1  flex h-10 w-full rounded-md border  border-blue-500 bg-transparent py-2 px-3 text-sm text-gray-900 focus:outline-none focus:ring-1  focus:ring-offset-1  focus:ring-offset-blue-400"
                        >
                          <option>online</option>
                          <option>cod</option>
                          <option>wallet</option>
                          {/* <option>Scotland</option>
                                <option>France</option>
                                <option>Belgium</option>
                                <option>Japan</option> */}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <ProductsCarousel id={productid} categoryid={productcategory} subcategory_id={subcategoryID} />
      <Bestsellers />
    </div>
  );
};

export default Product;
