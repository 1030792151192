import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-custom-alert";
import LoadingBar from "react-top-loading-bar";
import { useNavigate } from 'react-router-dom'
import Img from "../../assets/signup.jpg";
import { AiFillEye } from 'react-icons/ai'
import { AiFillEyeInvisible } from "react-icons/ai"
import backgroundImage  from '../../assets/kaeinyonnebg.jpeg'
const Signup = () => {
  const navigat = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [password, setPassword] = useState();

  const [otp, setOtp] = useState();
  const [mobile, setMobile] = useState();
  const [referCord, setReferCord] = useState()
  const [progress, setProgress] = useState(0);

  const [checkbox, setcheckbox] = useState(false)


  const [changePassword, setChangePassword] = useState(true);
  const changeIcon = changePassword === true ? false : true;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [])

  // useEffect(() => {
  //   const items = localStorage.getItem("test_mobile");

  //   setMobile(items);
  //   console.log("number", mobile);
  // }, [mobile]);

  //let mobile = number


  const hendelChake = () => {
    setcheckbox(true)
  }



  const submitData = async (e) => {
    setProgress(10);

    try {
      e.preventDefault();
      setProgress(75);
      await axios.post(
        process.env.REACT_APP_API_SIGNUP,
        {
          name: name,
          phone: number,
          email: email,
          password: password,
          friends_referal_code: referCord,
        }
      ).then((res) => {
        setProgress(100);

        toast.success(res.data.message);
        setMobile(number)
        setIsOpen(!isOpen);
        //window.location.href = "http://localhost:3000/signup";

      }).catch((error) => {
        if (error.response) { // status code out of the range of 2xx

          toast.warning(error.response.data.message)

          setProgress(100)
        } else if (error.request) { // The request was made but no response was received

          toast.error(error.request)
          setProgress(100)
        } else {// Error on setting up the request

          toast.error(error.message)
          setProgress(100)
        }
      });
    } catch (err) {
      setProgress(100);
      toast.error('Something Wrong')
    }
  };

  //  submit otp

  const submitOtp = async (e) => {
    setProgress(10);

    try {
      e.preventDefault();
      setProgress(75);
      await axios.post(
        process.env.REACT_APP_API_SUBMIT_OTP,
        {
          phone: mobile,
          otp: otp,
        }
      ).then((res) => {
        setProgress(100);
        toast.success(res.data.message);
        navigat('/login');
        // window.location.href = "http://localhost:3000/";

      }).catch((error) => {
        if (error.response) { // status code out of the range of 2xx

          toast.warning(error.response.data.message)

          setProgress(100)
        } else if (error.request) { // The request was made but no response was received

          toast.error(error.request)
          setProgress(100)
        } else {// Error on setting up the request

          toast.error(error.message)
          setProgress(100)
        }
      });
    } catch (err) {
      setProgress(100);
      toast.error('Something Wrong')
    }
  };

  // resendOtp

  const resendOtp = async (e) => {
    e.preventDefault();

    try {
      setProgress(75);
      await axios.post(
        process.env.REACT_APP_API_RESEND_OTP,
        {
          phone: mobile,
        }
      ).then((res) => {
        setProgress(100);

        toast.success(res.data.message);
        //window.location.href = "http://localhost:3000/signup";

      }).catch((error) => {
        if (error.response) { // status code out of the range of 2xx

          toast.warning(error.response.data.message)

          setProgress(100)
        } else if (error.request) { // The request was made but no response was received

          toast.error(error.request)
          setProgress(100)
        } else {// Error on setting up the request

          toast.error(error.message)
          setProgress(100)
        }
      });
    } catch (err) {
      setProgress(100);
      toast.error('Something Wrong')
    }
  };

  return (
    <div>
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <section className="text-gray-600 body-font min-h-screen">
        <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
          <div className="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 hidden lg:block">
            <img
              className="object-cover object-center rounded h-3/4 w-3/4"
              alt="hero"
              src={Img}
            />
          </div>
          <div className="lg:w-2/6 md:w-1/2 bg-gray-100 relative rounded-lg  shadow-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
            <h2 className="text-4xl font-bold cursor-pointer text-sky-500">Kaeinyonne</h2>

            <h2 className="text-gray-900 text-lg font-medium title-font">
              Welcome! 👋
            </h2>
            <h2 className="text-gray-900 text-sm font-medium title-font">
              Please sign-up to your account and start the adventure
            </h2>
            <form className="mt-6" >
              <div className="relative mb-4 text-left  ">
                <div className="mb-4">
                  <input
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Full Name"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="number"
                    onChange={(e) => setNumber(e.target.value)}
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Mobile Number"
                  />
                </div>
                <div className="mb-4">
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Email"
                  />
                </div>
                <div className="mb-4">
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    type={changePassword ? "password" : "text"}
                    className=" relative block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Password"
                  />
                  <span onClick={() => {
                    setChangePassword(changeIcon);
                  }}
                    className="absolute right-3 bottom-[104px] cursor-pointer">
                    {changeIcon ? <AiFillEye size={20} /> : <AiFillEyeInvisible size={20} />}
                  </span>
                </div>
                <div className="mb-4">
                  <input
                    onChange={(e) => setReferCord(e.target.value)}
                    type="text"
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Refer Code"
                  />
                </div>
                <div className="flex w-max gap-4">
                  <input type="checkbox" checked={checkbox} color="green" onClick={hendelChake} />
                  <a href="https://kaeinyonne.com/termsConditions" rel="noreferrer" target="_blank" className=" text-sky-600 ">
                    Terms & conditions
                  </a>
                </div>
              </div>
              <button disabled={checkbox === false} onClick={submitData} className={`inline-block px-6 mt-2 py-2.5 text-white bg-gradient-to-r ${checkbox === false ? " bg-gray-500" : "from-[#88C645] to-[#82c739]"} font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3`}>
                Sign up
              </button>
            </form>
            {isOpen && (
              <div
                className="relative z-10"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className=" sm:items-start">
                          <form className="mt-6">
                            <div className="relative mb-4 text-left  ">
                              <div className="mb-4">
                                <input
                                  onChange={(e) => setOtp(e.target.value)}
                                  type="text"
                                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                  placeholder="OTP"
                                />
                              </div>
                            </div>
                            <button
                              onClick={submitOtp}
                              className="inline-block px-6 py-2 border-2 border-[#88C645]  text-[#88C645] font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                            >
                              Submit
                            </button>
                            <button
                              onClick={resendOtp}
                              className="inline-block   ml-3 px-6 py-2 border-2 border-[#88C645]  text-[#88C645] font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                            >
                              Resend OTP
                            </button>
                          </form>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 lg:w-fill py-3 sm:flex sm:flex-row-reverse sm:px-6">

                        <button
                          onClick={() => setIsOpen(!isOpen)}
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}


            <div className="flex items-center justify-between pb-6 mt-5">
              <p className="mb-0 mr-2">You have already account?</p>
              <Link
                to="/login"
                type="button"
                className="inline-block px-6 py-2 border-2 border-[#88C645]  text-[#88C645] font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Signup;