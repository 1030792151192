import {Add_To_Cart,Empty_Cart,Remove_From_Cart} from './constant'

export const addToCart = () => {

  return {
    type: Add_To_Cart,
  };
};

export const removeFromCart = (data) => {
    return {
      type: Remove_From_Cart,
      data,
    };
  };


  export const emptyCart = () => {
    return {
      type: Empty_Cart,
      
    };
  };