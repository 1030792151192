import React, { useState, useEffect } from "react";
//import { AiOutlineShoppingCart } from "react-icons/ai";
import { Link } from "react-router-dom";
import Bestsellers from "../components/home/Bestsellers";
//import { addToCart } from "../redux/action";
import { useDispatch } from "react-redux";
import {
  product,
  productFilter
} from "../redux/productAction";
import { useSelector } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { AiFillHeart } from "react-icons/ai";
//import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-custom-alert";
import { BsArrowRightShort } from 'react-icons/bs'
import { BsArrowLeftShort } from 'react-icons/bs'
import { calculatPercentage } from "../utils/calculatPercentage";
const Products = () => {
  //const navigat = useNavigate();

  // const [category, setCategory] = useState([]);

  const [page, setPage] = useState(1)

  useEffect(() => {
    // if (!localStorage.getItem("test_token")) {
    //   navigat("/login");
    // }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);

  const dispatch = useDispatch();

  const data = useSelector((state) => state.productData);


  // const result = useSelector((state) => state.cartData);
  // console.log("cart data", result);

  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);

  let imgUrl = "https://kaeinyonne.com/admin/product_image/";

  const handleChange = (filter, id) => {
    setProgress(50);

    dispatch(productFilter(filter, id));
    setProgress(100);
    setShow(!show);
  };

  // const hendleAll = () => {
  //   dispatch(productList());
  //   setShow(!show);
  //   // window.location.reload(false);
  // };

  // useEffect(() => {
  //   setProgress(50);
  //   dispatch(productList());
  //   setProgress(100);
  // }, []);

  // useEffect(() => {
  //   setProgress(45);
  //   const categoryList = async () => {
  //     try {
  //       setProgress(75);
  //       await axios(process.env.REACT_APP_API_CATEGORY_LIST, {
  //         method: "get",
  //       })
  //         .then((res) => {
  //           console.log(res.data.category_list);
  //           setCategory(res.data.category_list);
  //           setProgress(100);
  //         })
  //         .catch((error) => {
  //           if (error.response) {
  //             // status code out of the range of 2xx
  //             console.log("Data :", error.response.data);
  //             toast.warning(error.response.data.message);
  //             console.log("Status :" + error.response.status);
  //             setProgress(100);
  //             //setProgress(progress + 100)
  //           } else if (error.request) {
  //             // The request was made but no response was received
  //             console.log(error.request);
  //             toast.error(error.request);
  //             setProgress(100);
  //             //setProgress(progress + 100)
  //           } else {
  //             // Error on setting up the request
  //             console.log("Error", error.message);
  //             toast.error(error.message);
  //             setProgress(100);
  //             //setProgress(progress + 100)
  //           }
  //         });
  //     } catch (err) {
  //       toast.error("Something Wrong");
  //       setProgress(100);
  //     }
  //   };
  //   categoryList();
  // }, []);

  // wishList
  const wishlistBtn = async (e) => {

    try {
      await axios(
        process.env.REACT_APP_API_ADD_WISHLIST,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("test_token")
            )}`,

            "Content-Type": "application/json",
          },
          data: {
            product_id: e.currentTarget.id,
          },
        }
      )
        .then((res) => {
          setProgress(75);
          toast.success(res.data.message);

          setProgress(100);
        })
        .catch((error) => {
          if (error.response) {

            toast.warning(error.response.data.message);

            setProgress(100);
          } else if (error.request) {

            toast.error(error.request);
            setProgress(100);
          } else {

            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      toast.error("Something Wrong");
    }
  };

  const selecPageHandler = (selectedPage) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= Math.ceil(data.length / 12) &&
      selectedPage !== page
    )
      setPage(selectedPage);
  };
  return (
    <div>
      <LoadingBar
        color="#FF8000"
        progress={progress}
        height={2}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="bg-white min-h-screen">
        <div>
          {/* mobile */}
          {show && (
            <div
              className="relative z-40 lg:hidden"
              role="dialog"
              aria-modal="true"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25"></div>

              <div className="fixed inset-0 z-40 flex mt-20">
                <div className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      onClick={() => setShow(!show)}
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                    >
                      <span className="sr-only">Close menu</span>

                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <form className="mt-4 border-t border-gray-200">
                    <h3 className="sr-only">Categories</h3>

                    <div className="border-t border-gray-200 px-4 py-6">
                      {/* <h3 className="-mx-2 -my-3 flow-root">
                        <span className="font-medium text-gray-900">
                          Category
                        </span>
                      </h3> */}
                      {/* <div
                        onClick={hendleAll}
                        className="mt-6 ml-3 cursor-pointer"
                      >
                        All
                      </div> */}

                      {/* {category.map((categoryData) => ( */}
                      <div
                        // key={categoryData.id}
                        className="pt-6"
                        id="filter-section-1"
                      >
                        <ul className="space-y-4">
                          <li className="flex items-center">
                            <div
                              // id={categoryData.id}
                              onClick={() => handleChange('latest_product', data[0].category_id)}
                              className="ml-3 text-sm font-semibold text-gray-800 hover:text-blue-500 cursor-pointer"
                            >
                              {/* {categoryData.category_name} */}
                              Latest Product
                            </div>
                          </li>

                          <li className="flex items-center">
                            <div
                              // id={categoryData.id}
                              onClick={() => handleChange('high_low', data[0].category_id)}
                              className="ml-3 text-sm font-semibold text-gray-800 hover:text-blue-500 cursor-pointer"
                            >
                              {/* {categoryData.category_name} */}

                              High to Low
                            </div>
                          </li>

                          <li className="flex items-center">
                            <div
                              // id={categoryData.id}
                              onClick={() => handleChange('low_high', data[0].category_id)}
                              className="ml-3 text-sm font-semibold text-gray-800 hover:text-blue-500 cursor-pointer"
                            >
                              {/* {categoryData.category_name} */}
                              Low To High
                            </div>
                          </li>
                          <li className="flex items-center">
                            <div
                              // id={categoryData.id}
                              onClick={() => handleChange('a_z', data[0].category_id)}
                              className="ml-3 text-sm font-semibold text-gray-800 hover:text-[#217CD1] cursor-pointer"
                            >
                              {/* {categoryData.category_name} */}
                              A To Z

                            </div>
                          </li>
                          <li className="flex items-center">
                            <div
                              // id={categoryData.id}
                              onClick={() => handleChange('z_a', data[0].category_id)}
                              className="ml-3 text-sm font-semibold text-gray-800 hover:text-[#217CD1] cursor-pointer"
                            >
                              {/* {categoryData.category_name} */}
                              Z To A

                            </div>
                          </li>
                          <li className="flex items-center">
                            <div
                              // id={categoryData.id}
                              onClick={() => handleChange('new_arrivals', data[0].category_id)}
                              className="ml-3 text-sm font-semibold text-gray-800 hover:text-[#217CD1] cursor-pointer"
                            >
                              {/* {categoryData.category_name} */}
                              New Arrivals

                            </div>
                          </li>
                          <li className="flex items-center">
                            <div
                              // id={categoryData.id}
                              onClick={() => handleChange('hightest_rating', data[0].category_id)}
                              className="ml-3 text-sm font-semibold text-gray-800 hover:text-[#217CD1] cursor-pointer"
                            >
                              {/* {categoryData.category_name} */}
                              Hightest Rating Product

                            </div>
                          </li>

                        </ul>
                      </div>
                      {/* ))} */}
                    </div>

                    {/* <div className="border-t border-gray-200 px-4 py-6">
                      <h3 className="-mx-2 -my-3 flow-root">
                        <span className="font-medium text-gray-900">Size</span>
                      </h3>

                      <div className="pt-6" id="filter-section-mobile-2">
                        <div className="space-y-6">
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-size-0"
                              name="size[]"
                              value="2l"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="filter-mobile-size-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500"
                            >
                              2L
                            </label>
                          </div>

                          <div className="flex items-center">
                            <input
                              id="filter-mobile-size-1"
                              name="size[]"
                              value="6l"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="filter-mobile-size-1"
                              className="ml-3 min-w-0 flex-1 text-gray-500"
                            >
                              6L
                            </label>
                          </div>

                          <div className="flex items-center">
                            <input
                              id="filter-mobile-size-2"
                              name="size[]"
                              value="12l"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="filter-mobile-size-2"
                              className="ml-3 min-w-0 flex-1 text-gray-500"
                            >
                              12L
                            </label>
                          </div>

                          <div className="flex items-center">
                            <input
                              id="filter-mobile-size-3"
                              name="size[]"
                              value="18l"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="filter-mobile-size-3"
                              className="ml-3 min-w-0 flex-1 text-gray-500"
                            >
                              18L
                            </label>
                          </div>

                          <div className="flex items-center">
                            <input
                              id="filter-mobile-size-4"
                              name="size[]"
                              value="20l"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="filter-mobile-size-4"
                              className="ml-3 min-w-0 flex-1 text-gray-500"
                            >
                              20L
                            </label>
                          </div>

                          <div className="flex items-center">
                            <input
                              id="filter-mobile-size-5"
                              name="size[]"
                              value="40l"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="filter-mobile-size-5"
                              className="ml-3 min-w-0 flex-1 text-gray-500"
                            >
                              40L
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          )}

          <main className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-6">
            <div className="flex items-baseline justify-between pt-9 pb-6">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                Products
              </h1>

              <div className="flex items-center">
                {/* <div className="relative inline-block text-left">
                  <div>
                    <button type="button" className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900" id="menu-button" aria-expanded="false" aria-haspopup="true">
                      Sort

                      <svg className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>


                  <div className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                    <div className="py-1" role="none">

                      <a href="#" className="font-medium text-gray-900 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Most Popular</a>

                      <a href="#" className="text-gray-500 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Best Rating</a>

                      <a href="#" className="text-gray-500 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">Newest</a>

                      <a href="#" className="text-gray-500 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-3">Price: Low to High</a>

                      <a href="#" className="text-gray-500 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-4">Price: High to Low</a>
                    </div>
                  </div>
                </div> */}

                {/* <button type="button" className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7">
                  <span className="sr-only">View grid</span>

                  <svg className="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.25 2A2.25 2.25 0 002 4.25v2.5A2.25 2.25 0 004.25 9h2.5A2.25 2.25 0 009 6.75v-2.5A2.25 2.25 0 006.75 2h-2.5zm0 9A2.25 2.25 0 002 13.25v2.5A2.25 2.25 0 004.25 18h2.5A2.25 2.25 0 009 15.75v-2.5A2.25 2.25 0 006.75 11h-2.5zm9-9A2.25 2.25 0 0011 4.25v2.5A2.25 2.25 0 0013.25 9h2.5A2.25 2.25 0 0018 6.75v-2.5A2.25 2.25 0 0015.75 2h-2.5zm0 9A2.25 2.25 0 0011 13.25v2.5A2.25 2.25 0 0013.25 18h2.5A2.25 2.25 0 0018 15.75v-2.5A2.25 2.25 0 0015.75 11h-2.5z" clipRule="evenodd" />
                  </svg>
                </button>  */}

                {/* mobile button */}

                <button
                  onClick={() => setShow(!show)}
                  type="button"
                  className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                >
                  <span className="sr-only">Filters</span>

                  <svg
                    className="h-5 w-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.628 1.601C5.028 1.206 7.49 1 10 1s4.973.206 7.372.601a.75.75 0 01.628.74v2.288a2.25 2.25 0 01-.659 1.59l-4.682 4.683a2.25 2.25 0 00-.659 1.59v3.037c0 .684-.31 1.33-.844 1.757l-1.937 1.55A.75.75 0 018 18.25v-5.757a2.25 2.25 0 00-.659-1.591L2.659 6.22A2.25 2.25 0 012 4.629V2.34a.75.75 0 01.628-.74z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <section aria-labelledby="products-heading" className=" pb-16">
              <h2 id="products-heading" className="sr-only">
                Products
              </h2>

              <div className="grid grid-cols-1 gap-x-3 gap-y-10 lg:grid-cols-4">
                <form className="hidden lg:block">
                  {/* <h3 className="sr-only">Categories</h3>
                  <ul role="list" className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
                    <li>
                      <a href="#">Totes</a>
                    </li>

                    <li>
                      <a href="#">Backpacks</a>
                    </li>

                    <li>
                      <a href="#">Travel Bags</a>
                    </li>

                    <li>
                      <a href="#">Hip Bags</a>
                    </li>

                    <li>
                      <a href="#">Laptop Sleeves</a>
                    </li>
                  </ul> */}

                  {/* <div className="border-b border-gray-200 py-6">
                    <h3 className="-my-3 flow-root">
                      <span className="font-medium text-gray-900">Color</span>
                    </h3>

                    <div className="pt-6" id="filter-section-0">
                      <div className="space-y-4">
                        <div className="flex items-center">
                          <input
                            id="filter-color-0"
                            name="color[]"
                            value="white"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor="filter-color-0"
                            className="ml-3 text-sm text-gray-600"
                          >
                            White
                          </label>
                        </div>

                        <div className="flex items-center">
                          <input
                            id="filter-color-1"
                            name="color[]"
                            value="beige"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor="filter-color-1"
                            className="ml-3 text-sm text-gray-600"
                          >
                            Beige
                          </label>
                        </div>

                        <div className="flex items-center">
                          <input
                            id="filter-color-2"
                            name="color[]"
                            value="blue"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor="filter-color-2"
                            className="ml-3 text-sm text-gray-600"
                          >
                            Blue
                          </label>
                        </div>

                        <div className="flex items-center">
                          <input
                            id="filter-color-3"
                            name="color[]"
                            value="brown"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor="filter-color-3"
                            className="ml-3 text-sm text-gray-600"
                          >
                            Brown
                          </label>
                        </div>

                        <div className="flex items-center">
                          <input
                            id="filter-color-4"
                            name="color[]"
                            value="green"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor="filter-color-4"
                            className="ml-3 text-sm text-gray-600"
                          >
                            Green
                          </label>
                        </div>

                        <div className="flex items-center">
                          <input
                            id="filter-color-5"
                            name="color[]"
                            value="purple"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor="filter-color-5"
                            className="ml-3 text-sm text-gray-600"
                          >
                            Purple
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="border-b border-gray-200 py-6">
                    {/* <h3 className="-my-3 flow-root">
                      <span className="font-medium text-gray-900">
                        Category
                      </span>
                    </h3> */}
                    {/* <div
                      onClick={hendleAll}
                      className="mt-6 ml-3 text-gray-800 hover:text-[#217CD1] cursor-pointer"
                    >
                      All
                    </div> */}

                    {/* {category.map((categoryData) => ( */}
                    <div
                      // key={categoryData.id}
                      className="pt-6"
                      id="filter-section-1"
                    >
                      <ul className="space-y-4">
                        <li className="flex items-center">
                          <div
                            // id={categoryData.id}
                            onClick={() => handleChange('latest_product', data[0].category_id)}
                            className="ml-3 text-sm font-semibold text-gray-800 hover:text-[#217CD1] cursor-pointer"
                          >
                            {/* {categoryData.category_name} */}
                            Latest Product
                          </div>
                        </li>
                        <li className="flex items-center">
                          <div
                            // id={categoryData.id}
                            onClick={() => handleChange('high_low', data[0].category_id)}
                            className="ml-3 text-sm font-semibold text-gray-800 hover:text-[#217CD1] cursor-pointer"
                          >
                            {/* {categoryData.category_name} */}

                            High to Low
                          </div>
                        </li>
                        <li className="flex items-center">
                          <div
                            // id={categoryData.id}
                            onClick={() => handleChange('low_high', data[0].category_id)}
                            className="ml-3 text-sm font-semibold text-gray-800 hover:text-[#217CD1] cursor-pointer"
                          >
                            {/* {categoryData.category_name} */}

                            Low To High
                          </div>
                        </li>
                        <li className="flex items-center">
                          <div
                            // id={categoryData.id}
                            onClick={() => handleChange('a_z', data[0].category_id)}
                            className="ml-3 text-sm font-semibold text-gray-800 hover:text-[#217CD1] cursor-pointer"
                          >
                            {/* {categoryData.category_name} */}
                            A To Z

                          </div>
                        </li>
                        <li className="flex items-center">
                          <div
                            // id={categoryData.id}
                            onClick={() => handleChange('z_a', data[0].category_id)}
                            className="ml-3 text-sm font-semibold text-gray-800 hover:text-[#217CD1] cursor-pointer"
                          >
                            {/* {categoryData.category_name} */}
                            Z To A

                          </div>
                        </li>
                        <li className="flex items-center">
                          <div
                            // id={categoryData.id}
                            onClick={() => handleChange('new_arrivals', data[0].category_id)}
                            className="ml-3 text-sm font-semibold text-gray-800 hover:text-[#217CD1] cursor-pointer"
                          >
                            {/* {categoryData.category_name} */}
                            New Arrivals

                          </div>
                        </li>
                        <li className="flex items-center">
                          <div
                            // id={categoryData.id}
                            onClick={() => handleChange('hightest_rating', data[0].category_id)}
                            className="ml-3 text-sm font-semibold text-gray-800 hover:text-[#217CD1] cursor-pointer"
                          >
                            {/* {categoryData.category_name} */}
                            Hightest Rating Product

                          </div>
                        </li>

                      </ul>
                    </div>
                    {/* ))} */}
                  </div>

                  {/* <div className="border-b border-gray-200 py-6">
                    <h3 className="-my-3 flow-root">
                      <span className="font-medium text-gray-900">Size</span>
                    </h3>

                    <div className="pt-6" id="filter-section-2">
                      <div className="space-y-4">
                        <div className="flex items-center">
                          <input
                            id="filter-size-0"
                            name="size[]"
                            value="2l"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor="filter-size-0"
                            className="ml-3 text-sm text-gray-600"
                          >
                            2L
                          </label>
                        </div>

                        <div className="flex items-center">
                          <input
                            id="filter-size-1"
                            name="size[]"
                            value="6l"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor="filter-size-1"
                            className="ml-3 text-sm text-gray-600"
                          >
                            6L
                          </label>
                        </div>

                        <div className="flex items-center">
                          <input
                            id="filter-size-2"
                            name="size[]"
                            value="12l"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor="filter-size-2"
                            className="ml-3 text-sm text-gray-600"
                          >
                            12L
                          </label>
                        </div>

                        <div className="flex items-center">
                          <input
                            id="filter-size-3"
                            name="size[]"
                            value="18l"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor="filter-size-3"
                            className="ml-3 text-sm text-gray-600"
                          >
                            18L
                          </label>
                        </div>

                        <div className="flex items-center">
                          <input
                            id="filter-size-4"
                            name="size[]"
                            value="20l"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor="filter-size-4"
                            className="ml-3 text-sm text-gray-600"
                          >
                            20L
                          </label>
                        </div>

                        <div className="flex items-center">
                          <input
                            id="filter-size-5"
                            name="size[]"
                            value="40l"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor="filter-size-5"
                            className="ml-3 text-sm text-gray-600"
                          >
                            40L
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </form>

                {/* productList */}
                <div className="lg:col-span-3">
                  <div className="h-full">
                    <div>
                      <section className="">
                        <div className="mx-auto grid max-w-screen-xl grid-cols-1 gap-2 p-2 md:grid-cols-2 lg:grid-cols-3">
                          {data.slice(page * 12 - 12, page * 12).map((item) => (
                            <article
                              key={item.id}
                              className="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl"
                            >
                              <>
                                <div className="relative flex items-end overflow-hidden justify-center">
                                  <Link
                                    onClick={() => dispatch(product(item.id))}
                                    to={`/product/${item.id}`}
                                    className="text-slate-700"
                                  >
                                    <img
                                      className="rounded-xl object-fill object-center h-52 w-auto"
                                      src={`${imgUrl}${item.product_image}`}
                                      alt=""
                                    />
                                  </Link>
                                  <div className="absolute top-3 right-3 inline-flex items-center rounded-full bg-white p-2 shadow-md">
                                    <button id={item.id} onClick={wishlistBtn}>
                                      <AiFillHeart
                                        className="text-red-400 hover:text-red-600"
                                        size={18}
                                      />
                                    </button>
                                  </div>
                                </div>
                                <div className="mt-1 p-1">

                                  {item.product_name}

                                  {/* <p className="text-slate-400 mt-1 text-sm">
                                    {item.tags}
                                  </p> */}

                                  <div className="mt-2 flex items-end justify-between">
                                    <div>
                                      <p className="space-x-2">
                                        <span className="lg:text-sm md:text-xs text font-semibold">
                                          ₹{item.sale_price_gst}
                                        </span>
                                        <span className="text-sm line-through text-gray-500">
                                          ₹{item.product_price}
                                        </span>
                                        <span className="text-xs text-red-700">
                                          {/* {(
                                            (100 *
                                              (item.product_price -
                                                item.sale_price)) /
                                            item.product_price
                                          ).toFixed()} */}
                                          {calculatPercentage(item.product_price,item.sale_price_gst)}
                                          %off
                                        </span>
                                      </p>
                                    </div>

                                    {/* <div className="flex justify-center mt-3">
                                      <button
                                        disabled={
                                          result.find(
                                            (cartItem) =>
                                              cartItem.id === item.id
                                          )
                                            ? true
                                            : false
                                        }
                                        onClick={(e) => handleCart(item)}
                                        className={`px-2 py-1 ${result.find(
                                          (cartItem) =>
                                            cartItem.id === item.id
                                        )
                                          ? "bg-gray-600"
                                          : "bg-[#88C645] hover:bg-[#F79946]"
                                          }  text-center text-sm text-white rounded duration-300`}
                                      >
                                        <AiOutlineShoppingCart />
                                      </button>
                                      <button className="lg:px-1 md:px-1 py-1 ml-1 bg-[#88C645] hover:bg-[#F79946] text-center text-sm text-white rounded duration-300">
                                        Buy Now
                                      </button>
                                    </div> */}
                                  </div>
                                </div>
                              </>
                            </article>
                          ))}
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
      {data.length > 0 && (
        <div className="flex justify-center items-center mb-5">
          <span
            onClick={() => selecPageHandler(page - 1)}
            className={`flex items-center px-2 py-2 mx-1 border cursor-pointer ${page > 1 ? "" : "opacity-0"
              } border-gray-800 rounded-full text-gray-900 hover:scale-105`}
          >
            <BsArrowLeftShort size={24} />
          </span>

          {
            [...Array(Math.ceil(data.length / 12))].map((_, i) => {
              return <span
                onClick={() => selecPageHandler(i + 1)}
                key={i}
                className={`flex items-center px-4 py-2 mx-1 border cursor-pointer border-gray-800 rounded-full ${page === i + 1 ? " bg-[#f79946b0]" : ""}  text-gray-900 hover:scale-105`}
              >
                {i + 1}
              </span>
            })
          }


          <span
            onClick={() => selecPageHandler(page + 1)}
            className={`flex items-center px-2 py-2 mx-1 border cursor-pointer ${page < Math.ceil(data.length / 12) ? "" : "opacity-0"} border-gray-800 rounded-full text-gray-900 hover:scale-105`}
          >
            <BsArrowRightShort size={24} />
          </span>
        </div>
      )}
      <Bestsellers />
    </div>

  );
};

export default Products;
