import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-custom-alert";
const Faq = () => {

  const [faqData, setfaqData] = useState([])
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });


    const faq = async () => {
      try {
        await axios(process.env.REACT_APP_API_FAQ_LIST, {
          method: "GET",
        })
          .then((res) => {
            setProgress(75);
            setfaqData(res.data.faq_list);

            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {

              toast.warning(error.response.data.message);

              setProgress(100);
            } else if (error.request) {

              toast.error(error.request);
              setProgress(100);
            } else {

              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    faq()

  }, [])

  return (

    <>
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="bg-gray-100 min-h-screen">
        <div className="container mx-auto">
          <div role="article" className="bg-gray-100 py-12 md:px-8">
            <div className="px-4 xl:px-0 py-10">
              <div className="flex flex-col lg:flex-row flex-wrap">
                <div className="mt-4 lg:mt-0 lg:w-3/5">
                  <div>
                    <h1 className="text-3xl ml-2 lg:ml-0 lg:text-4xl font-bold text-gray-900 tracking-normal lg:w-11/12">Frequently asked questions</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-6 xl:px-0">
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pb-6 gap-8">
                {faqData.map((items) =>
                  <div key={items.id} role="cell" className="bg-gray-100">
                    <div className="relative h-full w-full bg-gray-200 rounded-md shadow-md ">
                      <div className="p-3 bg-text-color">
                        <h1 className="pb-4 text-1xl font-semibold">{items.question}</h1>
                      </div>
                      <div className="p-3 ">
                        <div className="flex items-center pb-4 dark:border-gray-700 cursor-pointer w-full space-x-3">
                          <p className="text-md text-gray-900" dangerouslySetInnerHTML={{ __html: items.answer }} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
