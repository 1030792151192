import React, { useEffect, useState } from "react";
import { AiFillHeart } from "react-icons/ai";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-custom-alert";
import { useDispatch } from "react-redux";
import {
  product
} from "../../redux/productAction";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../App.css"
import { calculatPercentage } from "../../utils/calculatPercentage";
const Bestsellers = () => {
  const [faqData, setfaqData] = useState([])
  const [progress, setProgress] = useState(0);
  const [url, seturl] = useState()

  const dispatch = useDispatch();

  useEffect(() => {



    const faq = async () => {
      try {
        await axios(process.env.REACT_APP_API_SELLER_ADS_LIST, {
          method: "GET",
        })
          .then((res) => {
            setProgress(75);
            setfaqData(res.data.list);
            seturl(res.data.baseUrl)

            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {
              // status code out of the range of 2xx

              toast.warning(error.response.data.message);

              setProgress(100);
            } else if (error.request) {
              // The request was made but no response was received

              toast.error(error.request);
              setProgress(100);
            } else {
              // Error on setting up the request

              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    faq()

  }, [])


  // console.log("aaassssssss",faqData)



  // wishList
  const wishlistBtn = async (e) => {

    try {
      await axios(
        process.env.REACT_APP_API_ADD_WISHLIST,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("test_token")
            )}`,

            "Content-Type": "application/json",
          },
          data: {
            product_id: e.currentTarget.id,
          },
        }
      )
        .then((res) => {
          setProgress(75);
          toast.success(res.data.message);

          setProgress(100);
        })
        .catch((error) => {
          if (error.response) {
            // status code out of the range of 2xx

            toast.warning(error.response.data.message);

            setProgress(100);
          } else if (error.request) {
            // The request was made but no response was received

            toast.error(error.request);
            setProgress(100);
          } else {
            // Error on setting up the request

            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      toast.error("Something Wrong");
    }
  };


  // console.log("faqData",faqData)



  return (
    <div>
      <LoadingBar
        color="#FF8000"
        progress={progress}
        height={2}
        onLoaderFinished={() => setProgress(0)}
      />
      <p className="text-3xl font-fontHading leading-9 text-left ml-10 text-gray-800 lg:text-4xl mt-2">
        Sponsored Products
      </p>
      <div className="
    flex
      justify-center
      items-center
      flex-col
      px-2
      md:px-6
      xl:px-9
      py-3
      md:py-3
      xl:py-1
    ">

        <OwlCarousel
          className="owl-theme grid md:grid-cols-2 lg:grid-cols-4 justify-items-between mt-1 gap-y-8 lg:gap-y-0 gap-x-8"
          loop
          margin={6}
          autoplay={false}
          dots={false}
          autoPlay={true}
          autoplayTimeout={2500}
          autoplaySpeed={4000}
          autoplayHoverPause={true}
          items={8}
          autoWidth={true}
        >
          {faqData.slice(0, 8).map((add, index) =>
            <div key={`${add.product_id}-${index}`} className="flex flex-col rounded justify-center items-start p-2  bg-gray-200 sponser-sec">
              <div className="relative w-full">
                <Link
                  onClick={() => dispatch(product(add.product_id))}
                  to={`/product/${add.product_id}`}
                  className="flex justify-center items-center"
                >
                  <img
                    className="hidden lg:block rounded object-contain h-44 w-full"
                    src={url + add.product_image}
                    alt="watch"
                  />
                </Link>
                <div className="absolute bottom-16 left-1 inline-flex items-center rounded-full bg-white p-1 md:p-2 shadow-md">

                  <span className=" text-gray-900 ml-1 text-xs md:text-sm">{add.seller_name}</span>
                </div>

                <div className="absolute top-3 right-3 inline-flex items-center rounded-full bg-white p-1 md:p-2 shadow-md">
                  <button id={add.product_id} onClick={wishlistBtn}>

                    <AiFillHeart
                      className="text-red-400 hover:text-red-600"
                      size={15}
                    />
                  </button>
                </div>
                <div className="mt-4">
                  <div>

                    <p className=" text-lg font-normal leading-6 text-gray-800  w-44">
                      {add.product_name}
                    </p>

                  </div>
                  <div>
                    <p className="space-x-2">
                      <span className="text-lg font-semibold">₹{add.sale_price_gst}</span>
                      <span className="text-sm line-through text-gray-500">
                        ₹{add.product_price}
                      </span>
                      <span className="text-sm text-red-700">
                        {/* {(
                          (100 * (add.product_price - add.sale_price_gst)) /
                          add.product_price
                        ).toFixed()} */}
                        {
                          calculatPercentage(add.product_price,add.sale_price_gst)
                        }
                        %off
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

        </OwlCarousel>
      </div>
    </div>
  );
};

export default Bestsellers;
