import React, { useEffect, useState } from "react";
import Carousel from "../components/home/Carousel";
// import Hero from "../assets/hero.jpg";
// import { IoLogoGooglePlaystore } from "react-icons/io5";
// import { BsApple } from "react-icons/bs";
import Bestsellers from "../components/home/Bestsellers";
//import Product from "../components/home/ProductsCarousel";
import Highlight from "../components/home/Highlight";
import DealOfTheday from "../components/home/DealOfTheday";
import Subnavbar from "../components/nav/Subnavbar";
import NewArrivals from "../components/home/NewArrivals"
// import Banner1 from "../components/banner/Banner1";
// import Banner2 from "../components/banner/Banner2";
import Banner3 from "../components/banner/Banner3";
//import BestDeal from "../components/home/BestDeal";
// import SpinAndWin from "../components/SpinAndWin";
//import LogoCarousel from "../components/home/LogoCarousel";
//import { BsArrowUpCircleFill } from "react-icons/bs";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
// import El from "../assets/el.png"

import { toast } from "react-custom-alert";
import Bestseller from "../components/home/Bestseller"
const Home = () => {

  // const [showButton, setShowButton] = useState(false);





  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    // window.addEventListener("scroll", () => {
    //   if (window.pageYOffset > 450) {
    //     setShowButton(true);
    //   } else {
    //     setShowButton(false);
    //   }
    // });
  }, [])


  const [faqData, setfaqData] = useState([])

  const [url, seturl] = useState()

  useEffect(() => {


    const faq = async () => {
      try {
        await axios(process.env.REACT_APP_API_BANNER1_API, {
          method: "GET",
        })
          .then((res) => {

            setfaqData(res.data.banners_list);
            seturl(res.data.baseUrl)
        

          })
          .catch((error) => {
            if (error.response) {
            
              //toast.warning(error.response.data.message);
              // console.log("Status :" + error.response.status);

            } else if (error.request) {
              // The request was made but no response was received
           
              toast.error(error.request);

            } else {
              // Error on setting up the request
           
              toast.error(error.message);

            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    faq()

  }, [url])

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth", // for smoothly scrolling
  //   });
  // };


  return (
    <div>
      <Subnavbar />
      <section className="text-gray-600 body-font mt-2">
        <OwlCarousel
          className="owl-theme"
          loop={true}
          margin={20}
          autoplay={true}
          autoplayTimeout={2500}
          autoplaySpeed={2000}
          //autoplayHoverPause={true}
          items={1}
          // autoWidth={true}
          dots={false}
          responsive={
            {
              0: {
                items: 1
              },
              600: {
                items: 1
              },
              1000: {
                items: 1
              }
            }

          }
        >
          {faqData.map((item) =>
            <div key={item.id} className="item">
              {/* 1920*600 */}
              <img className="rounded md:h-[300px]  lg:h-[300px] h-[136px]  w-auto" src={url + item.banner_image} alt="" loading="lazy" />

            </div>
          )}
          {/* <div className="item">
        <img className="rounded-lg h-60 w-60" src={Img2} alt="" />
      </div>
      <div className="item">
        <img className="rounded-lg h-60 w-60" src={Img3} alt="" />
      </div>
      <div className="item">
        <img className="rounded-lg h-60 w-60" src={Img4} alt="" />
      </div> */}
          {/* <div className="item">
        <img src={Img3} alt="" />
      </div>
      <div className="item">
        <img src={Img4} alt="" />
      </div> */}
        </OwlCarousel>
      </section>
      {/* <Banner2 /> */}
      <Carousel />
      <Bestseller />
      <Banner3 />
      {/* <SpinAndWin /> */}
      <DealOfTheday />
      <NewArrivals/>
      <Bestsellers />
      {/* <BestDeal /> */}
      {/* <Banner1 /> */}
      {/* <LogoCarousel /> */}
      {/* <Product /> */}

      <Highlight />

      {/* {showButton && (
        <button
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "40px",
            right: "40px",
          }}
          className=" z-40 mb-10 shadow-xl animate-bounce bg-gray-200 sticky duration-500 border-[#164574]  w-12 transform hover:-translate-y-3   h-12 text-2xl rounded-full hover:bg-[#217CD1] hover:text-gray-200 text-[#217CD1] "
        >
          <i className=""></i>
          <div className=" ml-3">
            <BsArrowUpCircleFill size={25} />
          </div>
        </button>
      )} */}
    </div>
  );
};

export default Home;
