import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";
import { toast } from "react-custom-alert";
import ".././App.css";
import { BsArrowRightShort } from 'react-icons/bs'
import { BsArrowLeftShort } from 'react-icons/bs'
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  product
} from ".././redux/productAction";
import "../App.css";
import ReactStars from "react-stars";
const Cart = () => {
  const [selectedImages, setSelectedImages] = useState([null, null, null, null, null, null]);

  const [img, setimg] = useState([])

  const fileInputRef = React.createRef();

  const navigat = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localStorage.getItem("test_token")) {
      navigat("/login");
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [navigat]);

  const [oderData, setoderData] = useState([]);
  const [getReview, setgetReview] = useState(false);

  const [rating, setRating] = useState(null);
  const [review, setReview] = useState("");
  // const [userDeliveryAddres, setUserDeliveryAddres] = useState([])
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(1);
  // const [wbl, setwbl] = useState(null)
  const [cl, setcl] = useState(false)
  useEffect(() => {
    const oderList = async () => {
      try {
        await axios(
          process.env.REACT_APP_API_ORDER_LIST,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("test_token")
              )}`,

              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            setProgress(75);
            setoderData(res.data.product_list);

            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {

              toast.warning(" Please login");

              setProgress(100);
            } else if (error.request) {

              toast.error(error.request);
              setProgress(100);
            } else {

              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    // const deliveryAddres = async () => {

    //   try {
    //     await axios(
    //       "https://fusionproject.tk/FTL190170/kaeinyonne/api/user-delivery-address",
    //       {
    //         method: "POST",
    //         headers: {
    //           Authorization: `Bearer ${JSON.parse(localStorage.getItem('test_token'))}`,

    //           "Content-Type": "application/json",
    //         },
    //       }
    //     ).then((res) => {
    //       setProgress(75);
    //       setUserDeliveryAddres(res.data.address)
    //       console.log("address list", res.data.address)
    //        console.log(res.data.wishlist[1].product_details[1].id)
    //       setProgress(100);
    //     }).catch((error) => {
    //       if (error.response) { // status code out of the range of 2xx
    //         console.log("Data :", error.response.data);
    //         toast.warning(error.response.data.message)
    //         console.log("Status :" + error.response.status);
    //         setProgress(100)
    //       } else if (error.request) { // The request was made but no response was received
    //         console.log(error.request);
    //         toast.error(error.request)
    //         setProgress(100)
    //       } else {// Error on setting up the request
    //         console.log('Error', error.message);
    //         toast.error(error.message)
    //         setProgress(100)
    //       }
    //     });

    //   } catch (err) {
    //     toast.error('Something Wrong')
    //   }

    // }

    // deliveryAddres()

    oderList();
  }, []);

  let lent = oderData.length;



  const selecPageHandler = (selectedPage) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= Math.ceil(lent / 10) &&
      selectedPage !== page
    )
      setPage(selectedPage);
  };

  const trackOrder = async (oid) => {
    // console.log("eeeeee", oid)
    try {
      const response = await axios.post("https://kaeinyonne.com/admin/api/order-track", {
        order_id: oid
      });
      // console.log(response.data)
      const trackingUrl = response.data.result.data[1].tracking_url;
      window.open(trackingUrl, "_blank");
    } catch (error) {
      // console.error('Error:', error.message);
      // toast.error('Not yet delivered');
      if (error.response) {

        toast.warning(error.response.data.message);


      } else if (error.request) {

        toast.error(error.request);

      } else {

        toast.error(error.message);

      }
    }
  };

  const [cancelRequestReasonlist, setcancelRequestReasonlist] = useState([])

  const [returntReasonlist, setReturnReasonlist] = useState([])
  useEffect(() => {
    const cancelRequestReason = async () => {
      try {
        const response = await axios.get("https://kaeinyonne.com/admin/api/return-request-reason",);
        console.log("kkkkkrr return-request-reason", response.data)
        // const trackingUrl = response.data
        setcancelRequestReasonlist(response.data.count)

      } catch (error) {
        console.log(error)
        // toast.error('Something went wrong');
      }
    };
    cancelRequestReason()
  }, [])

  useEffect(() => {
    const returnReason = async () => {
      try {
        const response = await axios.get("https://kaeinyonne.com/admin/api/cancel-request-reason",);
        console.log("kkkkkrrppp cancel-request-reason", response.data)
        //const trackingUrl = response.data
        setReturnReasonlist(response.data.count)

      } catch (error) {
        console.error('Error:', error.message);
        // toast.error('Something went wrong');
      }
    };
    returnReason()
  }, [])

  const [reason, setreason] = useState()
  const [oderId, setoderId] = useState()
  const [oderStatus, setoderStatus] = useState()

  function handleSelectChange(event) {
    const selectedValue = event.target.value;
    setreason(selectedValue)
  }

  const handleCancelOpen = (oId, ostatus) => {
    setcl(!cl)
    setoderStatus(ostatus)
    setoderId(oId)
  }

  const handleCancelOrderSubmit = async () => {
    console.log(oderId, oderStatus, reason)
    // if (oderStatus === 1) {

    console.log("YYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY")
    try {
      const response = await axios.post("https://kaeinyonne.com/admin/api/order-track", {
        order_id: oderId
      });
      // setwbl(response.data.result.data[1].waybill)
      let wbl = response.data.result.data[1].waybill
      // console.log(wbl)
      if (wbl > 0) {
        try {
          const response = await axios.post("https://kaeinyonne.com/admin/api/order-cancel", {
            order_id: oderId,
            awb_number: wbl,
            reason: reason
          });
          toast.success(response.data.message)

          window.location.reload(false);
        } catch (error) {
          toast.error('Order is not Confirmed');
          setcl(!cl)
        }
      } else {
        toast.warning("Order is not Confirmed");
        setcl(!cl)
      }
      // console.log(response.data,"")
    } catch (error) {
      console.error('Error:', error.message);
      try {
        const response = await axios.post("https://kaeinyonne.com/admin/api/order-cancel", {
          order_id: oderId,
          awb_number: 'null',
          reason: reason
        });
        toast.success(response.data.message)
        window.location.reload(false);
      } catch (error) {
        // toast.error('Something went wrong');
        setcl(!cl)
      }
    }
    // } else {
    //   toast.warning("Order Pending");
    //   setcl(!cl)
    // }
  }

  // const handleCancelOrder = async (orid, status) => {
  //   // console.log("eeeeee", wbl, orid, status)
  //   if (status === 1) {
  //     try {
  //       const response = await axios.post("https://kaeinyonne.com/admin/api/order-track", {
  //         order_id: orid
  //       });
  //       // setwbl(response.data.result.data[1].waybill)
  //       let wbl = response.data.result.data[1].waybill
  //       console.log(wbl)
  //       if (wbl > 0) {
  //         try {
  //           const response = await axios.post("https://kaeinyonne.com/admin/api/order-cancel", {
  //             order_id: orid,
  //             awb_number: wbl
  //           });
  //           toast.success(response.data.message)
  //           window.location.reload(false);
  //         } catch (error) {
  //           toast.error('Something went wrong');
  //         }
  //       } else {
  //         toast.warning("Order is not Confirmed");
  //       }
  //       // console.log(response.data,"")
  //     } catch (error) {
  //       console.error('Error:', error.message);
  //       toast.error('Something went wrong');
  //     }
  //   } else {
  //     toast.warning("Order Pending");

  //   }
  // }



  // let userAdd = userDeliveryAddres.length &&
  //   userDeliveryAddres.map((item) => item.address)

  // let userCity = userDeliveryAddres.length &&
  //   userDeliveryAddres.map((item) => item.city)

  // let userstate = userDeliveryAddres.length &&
  //   userDeliveryAddres.map((item) => item.state)

  // let userPinCode = userDeliveryAddres.length &&
  //   userDeliveryAddres.map((item) => item.pin_code)

  // let userCountry = userDeliveryAddres.length &&
  //   userDeliveryAddres.map((item) => item.country)

  // console.log("add", userAdd)

  const [orderid, setorderid] = useState()

  const toggleGetReview = (id) => {

    setorderid(id)
    setgetReview(!getReview)
  };


  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  const userReview = async () => {

    try {
      await axios(
        process.env.REACT_APP_API_CUSTOMER_PRODUCT_REVIEW,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("test_token")
            )}`,

            "Content-Type": "application/json",
          },

          data: {
            product_id: orderid,
            review: review,
          },
        }
      )
        .then((res) => {
          setProgress(75);
          toast.success(res.data.message);

          setProgress(100);
          //setIsListOper(!isListOper)
          // window.location.reload(false);
        })
        .catch((error) => {
          if (error.response) {

            toast.warning(error.response.data.message);

            setProgress(100);
          } else if (error.request) {

            toast.error(error.request);
            setProgress(100);
          } else {

            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      toast.error("Something Wrong");
    }


    try {
      await axios(
        process.env.REACT_APP_API_CUSTOMER_PRODUCT_RATING,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("test_token")
            )}`,

            "Content-Type": "application/json",
          },

          data: {
            product_id: orderid,
            rating: rating,
          },
        }
      )
        .then((res) => {
          setProgress(75);
          toast.success(res.data.message);

          setProgress(100);
          //setIsListOper(!isListOper)
          window.location.reload(false);
        })
        .catch((error) => {
          if (error.response) {

            toast.warning(error.response.data.message);

            setProgress(100);
          } else if (error.request) {

            toast.error(error.request);
            setProgress(100);
          } else {

            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      toast.error("Something Wrong");
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [returntReason, setreturntReason] = useState()
  const [oidReturn, setoidReturn] = useState()
  const [ostatusReturn, setostatusReturn] = useState()

  const handleReturn = (oId, ostatus) => {
    setModalOpen(!isModalOpen)
    setoidReturn(oId)
    setostatusReturn(ostatus)
  }

  const handlereturntReasonSubmit = async () => {
    // console.log("rrrrr", returntReason, oidReturn, ostatusReturn)

    if (ostatusReturn === 1) {
      try {
        const response = await axios.post("https://kaeinyonne.com/admin/api/order-track", {
          order_id: oidReturn
        });

        let wbl = response.data.result.data[1].waybill
        // console.log("response owl", wbl)

        if (wbl > 0) {
          const formData = new FormData();
          formData.append("order_id", oidReturn);
          formData.append("awb_number", wbl);
          formData.append("return_reason", returntReason);

          // Assuming img is an array of File objects
          // console.log(img)
          img.forEach((image, index) => {
            if (image) {
              formData.append("return_image[]", image);
            }
          });


          try {
            // Send the POST request with FormData
            const returnResponse = await axios.post("https://kaeinyonne.com/admin/api/return-request", formData, {
              // headers: {
              //   'Content-Type': 'multipart/form-data', // Important: Set the correct content type
              // },
            });

            // Handle the response
            toast.success(returnResponse.data.message);
            setModalOpen(!isModalOpen)
            // console.log(returnResponse.data);
          } catch (error) {
            if (error.response) {

              toast.warning(error.response.data.result);

              setModalOpen(!isModalOpen)
            } else if (error.request) {

              toast.error(error.request);

            } else {

              toast.error(error.message);

            }
          }

        } else {
          toast.warning("Not yet delivered");
          setModalOpen(!isModalOpen);
        }

      } catch (error) {
        if (error.response) {

          toast.warning(error.response.data.message);

          setProgress(100);
        } else if (error.request) {

          toast.error(error.request);
          setProgress(100);
        } else {

          toast.error(error.message);
          setProgress(100);
        }
        setModalOpen(!isModalOpen);
      }

    } else {
      toast.warning("Not yet delivered");
      setModalOpen(!isModalOpen);
    }
  }

  const handleFileChange = (index, e) => {
    const file = e.target.files[0];

    setimg((prevImg) => [...prevImg, file]);

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const newImages = [...selectedImages];
        newImages[index] = reader.result; // Use reader.result instead of e.target.result
        setSelectedImages(newImages);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="lg:bg-white">
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      {oderData.length > 0 && (
        <div className="max-w-6xl px-4 mx-auto my-4 md:my-6">
          {oderData.slice(page * 10 - 10, page * 10).map((oder) => (
            <div
              key={oder.id}
              className="mt-8 md:flex-row flex flex-col border border-gray-300 rounded-lg overflow-hidden"
            >

              <div className="flex-1 ">
                <div className="p-8">
                  <ul className="divide-y divide-gray-200  -my-7">
                    <li className="flex items-stretch justify-between space-x-5 py-7">
                      <div className="flex items-stretch flex-1">
                        <div className="flex-shrink-0">
                          <Link
                            onClick={() => dispatch(product(oder.product_id))}
                            to={`/product/${oder.product_id}`}
                          >
                            <img
                              className="w-20 h-20 border border-gray-200 rounded-lg object-contain"
                              src={oder.image}
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="flex flex-col justify-between ml-5">
                          <div className="flex-1">
                            <p className="text-sm font-bold text-gray-900 ">
                              Product name: {oder.name}
                            </p>
                            <p className="mt-1.5 text-sm font-medium text-gray-500 ">
                              Payment mode: {oder.payment_mode}
                            </p>
                          </div>
                          {oder.product_color === null || oder.product_color === 'null' ? "" : (
                            <div className="color-sec mt-2">
                              <p className="color-text"> Color:  </p>
                              <span className="order-color-sec" style={{
                                backgroundColor: `${oder.product_color}`,
                                border: '1px solid',
                              }}>  </span>
                            </div>
                          )}

                          {oder.product_size === null || oder.product_size === 'null' ? "" : (<p className="mt-1.5 text-sm font-medium text-gray-500 ">
                            Product size: {oder.product_size}
                          </p>)}


                          <p className="mt-2 text-sm font-medium text-gray-500 ">
                            Quantity: {oder.quantity}
                          </p>
                          {+oder.delivery_charge === 0 || oder.delivery_charge === 'null' ? "" : (
                            <p className="mt-2 text-sm font-medium text-gray-500 ">
                              Delivery charges: {`₹ ${oder.delivery_charge}`}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-between ml-auto">
                        <p className="text-sm font-bold text-right text-gray-900 ">
                          ₹{oder.product_price}
                        </p>
                      </div>
                    </li>
                    {/* <li className="flex items-stretch justify-between space-x-5 py-7">
                    <div className="flex items-stretch flex-1">
                      <div className="flex-shrink-0">
                        <img
                          className="w-20 h-20 border border-gray-200 rounded-lg object-contain"
                          src="https://dev-ui-image-assets.s3.ap-south-1.amazonaws.com/order-confirmed/mwp22hn-a-apple-original-imag3qe9eqkfhmg8.jpeg?q=70"
                          alt="https://dev-ui-image-assets.s3.ap-south-1.amazonaws.com/order-confirmed/mwp22hn-a-apple-original-imag3qe9eqkfhmg8.jpeg?q=70" />
                      </div>
                      <div className="flex flex-col justify-between ml-5">
                        <div className="flex-1">
                          <p className="text-sm font-bold text-gray-900 ">
                            APPLE Airpods Pro with MagSafe Charging Case Bluetooth
                            Headset
                          </p>
                          <p
                            className="mt-1.5 text-sm font-medium text-gray-500 ">
                            White, True Wireless
                          </p>
                        </div>
                        <p
                          className="mt-4 text-sm font-medium text-gray-500 ">
                          x 1
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-between ml-auto">
                      <p
                        className="text-sm font-bold text-right text-gray-900 ">
                        ₹22,500
                      </p>
                    </div>
                  </li> */}
                  </ul>

                  {/* <div className="flex w-full items-center justify-center px-6">
                    <div className="h-full w-full ">
                      <div className="flex flex-col md:flex-row py-7">
                      
                        <div className="flex md:flex-col w-full md:w-1/5 relative pb-6 ">
                          <div className="flex justify-center items-center md:mb-4">
                            <div className=" bg-green-600 absolute top-1/2 md:top-auto md:left-1/2 h-1/4 md:h-1 w-1 md:w-1/2" />
                            <div className="p-2 z-10 bg-green-600 text-white rounded-full">
                             
                              <AiFillCheckCircle />
                            </div>
                          </div>
                          <div className="text-base font-semibold capitalize text-body-dark ltr:text-left rtl:text-right md:px-2 md:!text-center ml-2 md:ml-0">
                            Order Pending
                          </div>
                        </div>
                           <div className="flex md:flex-col w-full md:w-1/5 relative pb-6 ">
                          <div className="flex justify-center items-center md:mb-4">
                            <div className=" bg-green-600 absolute -top-1/4 md:top-auto md:left-0 h-full md:h-1 w-1 md:w-full" />
                            <div className="p-2 z-10 bg-green-600 text-white rounded-full">
                       
                              <AiFillCheckCircle />
                            </div>
                          </div>
                          <div className="text-base font-semibold capitalize text-body-dark ltr:text-left rtl:text-right md:px-2 md:!text-center ml-2 md:ml-0">
                            Order Processing
                          </div>
                        </div>
                      
                        <div className="flex md:flex-col w-full md:w-1/5 relative pb-6 ">
                          <div className="flex justify-center items-center md:mb-4">
                            <div className=" bg-gray-200 absolute -top-1/4 md:top-auto md:left-0 h-full md:h-1 w-1 md:w-full" />
                            <div className="z-10 h-8 w-8 border-dashed bg-white   text-green-600 border border-green-600 rounded-full justify-center items-center flex">
                              <span className="h-4 w-4 text-green-600 justify-center items-center flex">
                                3
                              </span>
                            </div>
                          </div>
                          <div className="text-base font-semibold capitalize text-body-dark ltr:text-left rtl:text-right md:px-2 md:!text-center ml-2 md:ml-0">
                            Order At Local Facility
                          </div>
                        </div>
                        <div className="flex md:flex-col w-full md:w-1/5 relative pb-6 ">
                          <div className="flex justify-center items-center md:mb-4">
                            <div className=" bg-gray-200 absolute -top-1/4 md:top-auto md:left-0 h-full md:h-1 w-1 md:w-full" />
                            <div className="z-10 h-8 w-8 border-dashed bg-white   text-green-600 border border-green-600 rounded-full justify-center items-center flex">
                              <span className="h-4 w-4 text-green-600 justify-center items-center flex">
                                4
                              </span>
                            </div>
                          </div>
                          <div className="text-base font-semibold capitalize text-body-dark ltr:text-left rtl:text-right md:px-2 md:!text-center ml-2 md:ml-0">
                            Order Out For Delivery
                          </div>
                        </div>
                        <div className="flex md:flex-col w-full md:w-1/5 relative pb-6 ">
                          <div className="flex justify-center items-center md:mb-4">
                            <div className=" bg-gray-200 absolute -top-1/4 md:top-auto md:left-0 h-1/2 md:h-1 w-1 md:w-1/2" />
                            <div className="z-10 h-8 w-8 border-dashed bg-white  text-green-600 border border-green-600 rounded-full justify-center items-center flex">
                              <span className="h-4 w-4 text-green-600 justify-center items-center flex">
                                5
                              </span>
                            </div>
                          </div>
                          <div className="text-base font-semibold capitalize text-body-dark ltr:text-left rtl:text-right md:px-2 md:!text-center ml-2 md:ml-0">
                            Order Completed
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="p-8 track-sec">
                  <div className="md:flex items-center justify-around">
                    <div className="mb-4">
                      <div className="text-sm text-gray-500 ">Order ID</div>
                      <div className="text-sm font-medium ">
                        {oder.order_id}
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="text-sm text-gray-500 ">Date</div>
                      <div className="text-sm font-medium ">{oder.order_date}</div>
                    </div>
                    <div className="mb-4">
                      <div className="text-sm text-gray-500 ">Total Amount With Gst</div>
                      <div className="text-sm font-medium ">
                        ₹{oder.total_price_with_gst}
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="text-sm text-gray-500">Order Status</div>
                      {oder.status === 0 && <div className="text-sm font-medium">Pending Order</div>}
                      {oder.status === 6 && <div className="text-sm font-medium">Cancel Order</div>}
                      {oder.status === 1 && <div className="text-sm font-medium">Confirmed Order</div>}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="mb-4 text-center mobile-grid">
                      <button onClick={() => trackOrder(oder.id)} className="text-sm font-medium rounded-md border border-green-600 px-3 py-0.5  leading-7 text-green-600 hover:text-white hover:bg-green-300 mb-4">
                        Track order
                      </button>

                      <a className="text-sm font-medium rounded-md border border-green-600 invoice-but md:ml-2  leading-7 text-green-600 hover:text-white hover:bg-green-300" target="_blank" rel="noreferrer" href={`https://kaeinyonne.com/admin/invoice-download/${oder.order_id}/${oder.id}`} >Invoice</a>

                      <button
                        onClick={() => handleCancelOpen(oder.id, oder.status)}
                        className={`text-sm font-medium ${oder.status === 6 ? "invisible" : "visible"} invoice-button rounded-md border border-green-600 px-3 ml-2 py-0.5 text-base font-semibold leading-7 text-green-600 hover:text-white hover:bg-green-300 mb-4`}>
                        Cancel Order
                      </button>



                      <button
                        onClick={() => toggleGetReview(oder.product_id)}
                        className="text-sm font-medium rounded-md border border-green-600 px-3 ml-2 py-0.5  leading-7 text-green-600 hover:text-white hover:bg-green-300 mb-4">
                        Reviews
                      </button>
                      {oder.payment_mode === "online" ? <button
                        onClick={() => handleReturn(oder.id, oder.status)}
                        className="text-sm font-medium rounded-md border border-green-600 px-3 ml-2 py-0.5  leading-7 text-green-600 hover:text-white hover:bg-green-300 mb-4">
                        Return
                      </button> : ""}


                      {isModalOpen && (
                        <>
                          <div
                            className="relative z-10"
                            aria-labelledby="modal-title"
                            role="dialog"
                          >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                            <div className="fixed inset-0 z-10 overflow-y-auto">
                              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                  <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className=" sm:items-start">
                                      <div className="mt-2 mb-5 ">



                                        <label htmlFor="countries" className="block mb-2 text-sm font-medium text-gray-900 ">Return reason</label>
                                        <select
                                          id="countries"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                          onChange={(e) => setreturntReason(e.target.value)}
                                        >
                                          {cancelRequestReasonlist.map((item, index) => (
                                            <option key={index} value={item.value} selected={index === 0}>
                                              {item.reason}
                                            </option>
                                          ))}

                                        </select>
                                        <div className="grid grid-cols-1 gap-3 sm:grid-cols-3 mt-8">

                                          {selectedImages.map((image, index) => (
                                            <div key={index} className="flex items-center justify-center w-full">

                                              <label
                                                htmlFor={`dropzone-file-${index}`}
                                                className="flex flex-col items-center justify-center w-full h-36 border-2 border-gray-100 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 hover:bg-gray-100 "
                                              >
                                                {image ? (
                                                  <img src={image} alt={`Selected ${index}`} className="w-36 h-36 object-cover" />
                                                ) : (
                                                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                    <svg
                                                      className="w-8 h-8 mb-4 text-gray-500 "
                                                      aria-hidden="true"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 20 16"
                                                    >
                                                      <path
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                                      />
                                                    </svg>
                                                  </div>
                                                )}
                                                <input
                                                  id={`dropzone-file-${index}`}
                                                  type="file"
                                                  className="hidden"
                                                  ref={fileInputRef}
                                                  onChange={(e) => handleFileChange(index, e)}
                                                />
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="bg-gray-50 px-4 lg:w-fill py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                      onClick={() => setModalOpen(!isModalOpen)}
                                      type="button"
                                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      disabled={!returntReason}
                                      onClick={handlereturntReasonSubmit}
                                      type="button"
                                      className={`inline-flex w-full justify-center rounded-md border border-transparent ${!returntReason ? " bg-gray-500" : "bg-green-500 hover:bg-green-700 "} px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {
                        cl && (
                          <div
                            className="relative z-10"
                            aria-labelledby="modal-title"
                            role="dialog"
                          >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                            <div className="fixed inset-0 z-10 overflow-y-auto">
                              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                  <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className=" sm:items-start">
                                      <div className="mt-2 mb-10 ">



                                        <label htmlFor="countries" className="block mb-2 text-sm font-medium text-gray-900 ">Cancel reason</label>
                                        <select
                                          id="countries"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                          onChange={handleSelectChange}
                                        >
                                          {returntReasonlist.map((item, index) => (
                                            <option key={index} value={item.value} selected={index === 0}>
                                              {item.reason}
                                            </option>
                                          ))}

                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="bg-gray-50 px-4 lg:w-fill py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                      onClick={() => setcl(!cl)}
                                      type="button"
                                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      disabled={!reason}
                                      onClick={handleCancelOrderSubmit}
                                      type="button"
                                      className={`inline-flex w-full justify-center rounded-md border border-transparent ${!reason ? " bg-gray-500" : "bg-green-500 hover:bg-green-700 "} px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        )
                      }

                      {getReview && (
                        <div
                          className="relative z-10"
                          aria-labelledby="modal-title"
                          role="dialog"
                        >
                          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                          <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                  <div className=" sm:items-start">
                                    <div className="mt-6">
                                      <div className="mb-6">
                                        <textarea
                                          onChange={(e) =>
                                            setReview(e.target.value)
                                          }
                                          rows="10"
                                          name="message"
                                          placeholder="Your Message"
                                          className="text-body-color bg-white rounded border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-indigo-200 border-[f0f0f0] focus:border-primary w-full border py-3 px-[14px] text-base outline-none focus-visible:shadow-none"
                                        ></textarea>
                                      </div>
                                      <div className=" ml-2">
                                        <p>Rate us</p>
                                        <ReactStars
                                          count={5}
                                          onChange={ratingChanged}
                                          size={30}
                                          edit={true}
                                          half={false}
                                          color2={"#facc15"}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="bg-gray-50 px-4 lg:w-fill py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                  <button
                                    onClick={() => setgetReview(!getReview)}
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    //disabled={!rating || !review}
                                    onClick={() => userReview()}
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    </div>

                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {lent > 0 && (
        <div className="flex justify-center items-center mb-5">
          <span
            onClick={() => selecPageHandler(page - 1)}
            className={`flex items-center px-2 py-2 mx-1 border cursor-pointer ${page > 1 ? "" : "opacity-0"
              } border-gray-800 rounded-full text-gray-900 hover:scale-105 hover:bg-[#f79946b0]`}
          >
            <BsArrowLeftShort size={24} />
          </span>

          {[...Array(Math.ceil(lent / 10))].map((_, i) => {
            return (
              <span
                onClick={() => selecPageHandler(i + 1)}
                key={i}
                className={`flex  listHid items-center px-4 py-2 mx-1 border cursor-pointer border-gray-800 rounded-full ${page === i + 1 ? " bg-[#f79946b0]" : ""
                  }  text-gray-900 hover:scale-105`}
              >
                {i + 1}
              </span>
            );
          })}

          <span
            onClick={() => selecPageHandler(page + 1)}
            className={`flex items-center px-2 py-2 mx-1 border cursor-pointer ${page < Math.ceil(lent / 10) ? "" : "opacity-0"
              } border-gray-800 rounded-full text-gray-900 hover:scale-105 hover:bg-[#f79946b0]`}
          >
            <BsArrowRightShort size={24} />
          </span>
        </div>
      )}
    </div>
  );
};

export default Cart;
