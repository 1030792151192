import React, { useState, useEffect } from "react";
import { FiSettings } from "react-icons/fi";
import { BsBag } from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { Link } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-custom-alert";
import axios from "axios";
import { useNavigate } from 'react-router-dom'
import UserIcon from '../../assets/usericon.png'
const User = () => {
  const navigat = useNavigate()
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);


  const [imgUrl, setImgUrl] = useState();
  const [userInfo, setUserInfo] = useState([]);

  const [token, settoken] = useState()

  useEffect(() => {
    if (!localStorage.getItem('test_token')) {
      settoken(0)
    } else {
      settoken(1)
    }

  }, [])

  useEffect(() => {
    // userInfo auto update
    const userData = async () => {
      try {
        await axios(process.env.REACT_APP_API_USER_DETAILS, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("test_token")
            )}`,

            "Content-Type": "application/json",
          },
        }).then((res) => {
          //setProgress(progress + 75);
          setUserInfo(res.data.userDetails);
          setImgUrl(res.data.baseUrl);
          //setProgress(progress + 100);
        }).catch((error) => {
          if (error.response) { // status code out of the range of 2xx

            toast.warning(" Please login")

          } else if (error.request) { // The request was made but no response was received

            toast.error(error.request)
          } else {// Error on setting up the request

            toast.error(error.message)
          }
        })
      } catch (err) {
        toast.error('Something Wrong')
      }
    };

    userData();
  }, []);


  // user logout
  const userLoguot = () => {
    setProgress(progress + 75);
    localStorage.removeItem('test_token');
    toast.success('User logged out');
    window.location.reload(false);
    setProgress(progress + 100);
    setShow(!show)
    navigat('/login');
    //window.location.href = "http://localhost:3000/login";
  }

  return (
    <div>
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="relative inline-block text-left">
        <div>
          <button
            onClick={() => setShow(!show)}
            className="ml-1 lg:ml-4 relative inline-block"
          >
            <div
              className="flex-shrink-0 cursor-pointer">
              <img src={`${imgUrl}${userInfo.profile_image}`} alt='' loading="lazy" onError={(event) => event.target.src = UserIcon}
                className="rounded-full w-7 h-7 border border-gray-200  object-cover" />
            </div>
          </button>
        </div>

        {show && (
          <div
            duration={500}
            className="absolute right-0 z-10 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1"
            onMouseLeave={() => setShow(false)}
          >
            <div className="py-1 ml-3" role="none">
              <Link to={"/userprofile"}
                onClick={() => setShow(!show)}
                className="flex items-center text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-500 group px-4 py-2"
              >
                <span className="w-4 h-4 mr-2 text-gray-400 dark:text-gray-500 group-hover:text-blue-600 dark:group-hover:text-blue-500">
                  <FiSettings />
                </span>
                My Account
              </Link>
              <Link
                to='/order'
                onClick={() => setShow(!show)}
                className="flex items-center text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-500 group px-4 py-2"
              >
                <span className="w-4 h-4 mr-2 text-gray-400 dark:text-gray-500 group-hover:text-blue-600 dark:group-hover:text-blue-500">
                  <BsBag />
                </span>
                My Order
              </Link>
              <Link
                to="/wishlist"
                onClick={() => setShow(!show)}
                className="flex items-center text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-500 group px-4 py-2"
              >
                <span className="w-4 h-4 mr-2 text-gray-400 dark:text-gray-500 group-hover:text-blue-600 dark:group-hover:text-blue-500">
                  <AiOutlineHeart />
                </span>
                Wishlist
              </Link>
             
            </div>
            <div className="py-1" role="none">
              <button
                onClick={userLoguot}
                className={`flex items-center ${token === 1 ? "inline" : "hidden"}  text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-500 group px-4 py-2`}
              >
                <span className="w-4 h-4 mr-2 text-gray-400 dark:text-gray-500 group-hover:text-blue-600 dark:group-hover:text-blue-500">
                  <BiLogOut />
                </span>
                Log Out
              </button>
            </div>
         
          </div>
        )}
      </div>
    </div>
  );
};

export default User;
