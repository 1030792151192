
import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-custom-alert";
const Privacypolicy = () => {

  const [faqData, setfaqData] = useState([])
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });


    const faq = async () => {
      try {
        await axios(process.env.REACT_APP_API_PRIVACY_POLICY, {
          method: "GET",
        })
          .then((res) => {
            setProgress(75);
            setfaqData(res.data.list);
          
            setProgress(100);
          })
          .catch((error) => {
            if (error.response) {
              // status code out of the range of 2xx

              toast.warning(error.response.data.message);
  
              setProgress(100);
            } else if (error.request) {
              // The request was made but no response was received
     
              toast.error(error.request);
              setProgress(100);
            } else {
              // Error on setting up the request
    
              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    faq()

  }, [])



  return (
    <div className="bg-gray-100">
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="container mx-auto px-4">
      <div className="gradient-form bg-gray-100 p-5">
      <p className="text-3xl font-fontHading leading-9 text-center text-gray-800 lg:text-4xl">
      Privacy Policy
      </p>
        {faqData.map((iten) =>
          <p
            key={iten.id}
            className="mt-4"
            dangerouslySetInnerHTML={{ __html: iten.privacy_policy }}
          />
        )}
      </div>
      </div>
    </div>
  );
};

export default Privacypolicy;
