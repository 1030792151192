import React, { useState, useEffect } from "react";
import Img from "../assets/logo2.png";
import { Link } from "react-router-dom";
import axios from 'axios'
import { toast } from 'react-custom-alert';

const Footer = () => {

  const [data, setData] = useState([])

  const [social, setsocial] = useState([])


  const url = "https://kaeinyonne.com/admin/social_media_icon/"
  useEffect(() => {
    const contactData = async () => {

      try {

        await axios.get(process.env.REACT_APP_API_CONTACT_API,
        ).then((res) => {

          setData(res.data.list)

          //navigat('/')
        }).catch((error) => {
          if (error.response) { // status code out of the range of 2xx
            toast.warning(error.response.data.message)

          } else if (error.request) { // The request was made but no response was received
        
            toast.error(error.request)

          } else {// Error on setting up the request

            toast.error(error.message)

          }
        })

      } catch (err) {
        toast.error('Something Wrong')
      }
    }


    const socialList = async () => {

      try {

        await axios.get(process.env.REACT_APP_API_SOCIAL_MEDIA,
        ).then((res) => {

          setsocial(res.data.social_media_list)

          //navigat('/')
        }).catch((error) => {
          if (error.response) { // status code out of the range of 2xx
            toast.warning(error.response.data.message)

          } else if (error.request) { // The request was made but no response was received

            toast.error(error.request)

          } else {// Error on setting up the request

            toast.error(error.message)

          }
        })

      } catch (err) {
        toast.error('Something Wrong')
      }
    }

    socialList()

    contactData()
  }, [])

  // let socialMediaList = [];
  // socialMediaList =
  // social.length && social.map((item) => item.link);

  // console.log("socialMediaList", socialMediaList);


  return (
    <div>
      <footer className="text-gray-600 body-font bg-gradient-to-r from-[#217CD1] to-[#164574]">
        <div className="container  px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
          <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
            <div className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
              <img className="h-16" src={Img} alt="" />
              <span className="ml-3 text-l font-fontHero text-white">KAEINYONNE</span>
            </div>
            {data.map((item) =>
              <p key={item.id} className=" text-base leading-6 font-normal text-white mt-6 lg:w-full md:w-9/12 w-full">
                <span className=" font-semibold cursor-pointer ">
                  City - {item.address}{" "}
                </span>
                <br />
                <span className=" font-semibold cursor-pointer">Mobile - {item.phone}</span>{" "}
                <br />
                <span className=" font-semibold cursor-pointer">
                  Email - {item.email}
                </span>
              </p>
            )}
          </div>
          <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center justify-end">
            <div className="lg:w-1/4 md:w-1/2 w-full px-4">
              <h2 className="title-font font-medium text-white tracking-widest text-lg mb-3">
                ABOUT
              </h2>
              <nav className="list-none mb-10 text-base">
                <li>
                  <Link
                    to="/about"
                    className="text-white hover:text-[#F79946]"
                  >
                    About us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contactus"
                    className="text-white hover:text-[#F79946]"
                  >
                    Contact us
                  </Link>
                </li>
                <li>
                  <Link to="/rateus" className="text-white hover:text-[#F79946]">Rate us</Link>
                </li>
                <li>
                  <a href="https://kaeinyonne.com/admin/seller/login" target="_blank" rel="noreferrer" className="text-white hover:text-[#F79946]">
                    Become a seller
                  </a>
                </li>
              </nav>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full px-4">
              <h2 className="title-font font-medium text-white tracking-widest text-lg mb-3">
                HELP
              </h2>
              <nav className="list-none mb-10 text-base">
                <li>
                  <Link
                    to="/service"
                    className="text-white hover:text-[#F79946]"
                  >
                    Premium Service
                  </Link>
                </li>
                <li>
                  <Link to="/faq" className="text-white hover:text-[#F79946]">
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link to="/gift" className="text-white hover:text-[#F79946]">Gift Card</Link>
                </li>
                <li>
                  <Link to="/userprofile" className="text-white hover:text-[#F79946]">
                    Refer & Earn
                  </Link>
                </li>
              </nav>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full px-4">
              <h2 className="title-font font-medium text-white tracking-widest text-lg mb-3">
                POLICY
              </h2>
              <nav className="list-none mb-10 text-base">
                <li>
                  <Link
                    to="/termsConditions"
                    className="text-white hover:text-[#F79946]"
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacypolicy"
                    className="text-white hover:text-[#F79946]"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/returnpolicy"
                    className="text-white hover:text-[#F79946]">
                    Cancellation & Refund
                  </Link>
                </li>
                <li>

                  <Link to="/Cancellation" className="text-white hover:text-[#F79946]">
                    Shipping & Delivery Policy
                  </Link>
                </li>
              </nav>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
            <p className=" text-black text-sm text-center sm:text-left">
              © 2023 Kaeinyonne PVT LTD
            </p>
            <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
              <div className="grid grid-cols-6 gap-2 ml-10">
                {social.map((item) =>
                  <a
                    key={item.id}
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    className="bg-white  shadow-xl transform hover:-translate-y-3  border-2 w-12 h-12 rounded-full duration-500 text-blue-400 border-blue-400  hover:text-white text-2xl"
                  >
                    <i className="fab fa-twitter"></i>
                    <div className=" ml-2.5 mt-2.5">
                      <img src={url + item.icon} alt="" className=" h-6" />
                    </div>
                  </a>
                )}
              </div>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
