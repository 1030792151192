
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import Img1 from "../../assets/swiper1.png";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link} from "react-router-dom";
import {
  productByCategory,
} from "../../redux/productAction";

import { toast } from "react-custom-alert";

import '../../App.css'

const Carousel = () => {
  const dispatch = useDispatch();

  const [faqData, setfaqData] = useState([])

  const [url, seturl] = useState()

  useEffect(() => {


    const faq = async () => {
      try {
        await axios(process.env.REACT_APP_API_CATEGORY_LIST, {
          method: "GET",
        })
          .then((res) => {

            setfaqData(res.data.category_list);
            seturl(res.data.baseUrl)
        

          })
          .catch((error) => {
            if (error.response) {
        
              //toast.warning(error.response.data.message);
              // console.log("Status :" + error.response.status);

            } else if (error.request) {
              // The request was made but no response was received
          
              toast.error(error.request);

            } else {
              // Error on setting up the request
         
              toast.error(error.message);

            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    faq()

  }, [url])

  // const handleChange = (e) => {

  //   console.log("id", e.currentTarget.id);
  //   dispatch(productByCategory(e.currentTarget.id));
  //   navigat("/products")

  // };



  return (
    <OwlCarousel
      className="owl-theme mt-6"
      loop={true}
      margin={5}
      autoplay={true}
      autoplayTimeout={2500}
      autoplaySpeed={2000}
      autoplayHoverPause={true}
      items={5}
      //autoWidth={true}
      dots={false}
      //nav
      responsive={
        {
          0: {
            items: 2
          },
          600: {
            items: 4
          },
          1000: {
            items: 6
          }
        }

      }
    >
      {faqData.map((item) =>
        <div key={item.id} className="item item_cs">
          <Link to="/products" onClick={() => dispatch(productByCategory(item.id))}>
            <img className="object-cover product_category mb-5 cursor-pointer shadow-lg " src={url + item.icon} alt="" loading="lazy" />
          </Link>
        </div>
      )}
      {/* <div className="item">
        <img className="rounded-lg h-60 w-60" src={Img2} alt="" />
      </div>
      <div className="item">
        <img className="rounded-lg h-60 w-60" src={Img3} alt="" />
      </div>
      <div className="item">
        <img className="rounded-lg h-60 w-60" src={Img4} alt="" />
      </div> */}
      {/* <div className="item">
        <img src={Img3} alt="" />
      </div>
      <div className="item">
        <img src={Img4} alt="" />
      </div> */}
    </OwlCarousel>
  );
};

export default Carousel;
