import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/nav/Navbar";
import About from "./pages/About";
import Contactus from "./pages/Contactus";
import Footer from "./components/Footer";
import Order from "./components/Order";
import LogIn from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import Service from "./components/footer/Service";
import Faq from "./components/footer/Faq";
import Termsconditions from "./components/footer/Termsconditions";
import Privacypolicy from "./components/footer/Privacypolicy";
// import Ordertracking from "./components/Ordertracking";
import Wishlist from "./components/user/Wishlist";
import Products from "./pages/Products";
import Product from "./components/product/Product";
// import Orderhistory from "./components/user/Orderhistory";
import Userprofile from "./components/user/Userprofile";
import { ToastContainer } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css';
import Forgotpassword from "./pages/auth/Forgotpassword";
import Gift from "./components/footer/Gift";
import RateUs from "./components/footer/RateUs";
import Carthistory from "./components/user/Carthistory";
import Page404 from "./pages/Page404";
import Returnpolicy from "./components/footer/Returnpolicy";
import Cancellation from "./components/footer/Cancellation";
import Transactionhistory from "./components/user/Transactionhistory";

function App() {
  return (
    <>
      {/* {!localStorage.getItem('test_token') ? null :  } */}

      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />

        {/* <Route path="/orderhistory" element={<Orderhistory />} /> */}
        <Route path="/order" element={<Order />} />

        {/* auth */}
        <Route path="/login" element={<LogIn />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotpassword" element={<Forgotpassword />} />


        <Route path="/service" element={<Service />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/termsConditions" element={<Termsconditions />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/returnpolicy" element={<Returnpolicy />} />
        <Route path="/Cancellation" element={<Cancellation />} />
        {/* <Route path="/ordertrackin" element={<Ordertracking />} /> */}
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/about" element={<About />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/gift" element={<Gift />} />
        <Route path="/rateus" element={<RateUs />} />
        <Route path="/Carthistory" element={<Carthistory />} />

        <Route path="/products" element={<Products />} />
        <Route path="/product/:slug" element={<Product />} />
         


        <Route path="/userprofile" element={<Userprofile />} />
        <Route path="/Transactionhistory" element={<Transactionhistory/>} />

        <Route path="*" element={<Page404 />} />
      </Routes>
      <ToastContainer floatingTime={3000} />
      <Footer />
    </>
  );
}

export default App;
