import React, { useState, useEffect } from "react";
import LoadingBar from "react-top-loading-bar";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-custom-alert";
import { useNavigate } from "react-router-dom";
import Img from "../../assets/forgetpass.jpg";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import OtpInput from "react-otp-input";
import "../../App.css"
import backgroundImage from '../../assets/kaeinyonnebg.jpeg'
const Forgotpassword = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const navigat = useNavigate();

  const [otp, setOtp] = useState();
  const [mobile, setMobile] = useState();

  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [userNumber, setUserNumber] = useState();
  const [progress, setProgress] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [otpin, setotpin] = useState(false);

  const [changePassword, setChangePassword] = useState(true);
  const changeIcon = changePassword === true ? false : true;

  // useEffect(() => {
  //     const items = localStorage.getItem("test_mobile");

  //     setMobile(items);
  //     console.log("number", mobile);
  // }, [mobile]);

  // forget-password
  const forgetPassword = async (e) => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[1-9]\d{9}$/;


    if (emailRegex.test(userNumber) || mobileRegex.test(userNumber)) {
      setotpin(!otpin);

      setProgress(10);

      try {
        e.preventDefault();
        setProgress(75);
        await axios
          .post(process.env.REACT_APP_API_FORGET_PASSWORD, {
            phone: userNumber,
          })
          .then((res) => {
            setProgress(100);
            //localStorage.setItem("test_mobile", userNumber);
            toast.success(res.data.message);

            setMobile(userNumber);
            //navigat('/login');
            //window.location.href = "http://localhost:3000/forgotpassword/";
          })
          .catch((error) => {
            if (error.response) {

              toast.warning(error.response.data.message);

              setProgress(100);
            } else if (error.request) {

              toast.error(error.request);
              setProgress(100);
            } else {

              toast.error(error.message);
              setProgress(100);
            }
          });
      } catch (err) {
        setProgress(100);
        toast.error("Something Wrong");
      }
    } else {
      toast.warning("invalid number or email");
    }




  };

  //  forget-password-otp-verification
  const submitOtp = async (e) => {
    setProgress(10);


    try {
      e.preventDefault();
      setProgress(75);
      await axios
        .post(process.env.REACT_APP_API_FORGET_VERIFICATION, {
          phone: mobile,
          otp: otp,
        })
        .then((res) => {
          setProgress(100);
          toast.success(res.data.message);
          setIsOpen(!isOpen);
          //navigat('/login');
          // window.location.href = "http://localhost:3000/";
        })
        .catch((error) => {
          if (error.response) {

            toast.warning(error.response.data.message);

            setProgress(100);
          } else if (error.request) {

            toast.error(error.request);
            setProgress(100);
          } else {

            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      setProgress(100);
      toast.error("Something Wrong");
    }
  };

  // resend Otp
  const resendOtp = async (e) => {
    setProgress(10);
    e.preventDefault();

    try {
      setProgress(75);
      await axios
        .post(process.env.REACT_APP_API_RESEND_OTP, {
          phone: mobile,
        })
        .then((res) => {
          setProgress(100);
          toast.success(res.data.message);
          //window.location.href = "http://localhost:3000/forgotpassword";
        })
        .catch((error) => {
          if (error.response) {

            toast.warning(error.response.data.message);

            setProgress(100);
          } else if (error.request) {
            toast.error(error.request);
            setProgress(100);
          } else {
            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      setProgress(100);
      toast.error("Something Wrong");
    }
  };

  // set password

  const setPasswordFun = async (e) => {
    setProgress(10);
    e.preventDefault();
    try {
      setProgress(75);
      await axios
        .post(process.env.REACT_APP_API_SET_PASSWORD, {
          phone: mobile,
          new_password: newPassword,
          confirm_password: confirmPassword,
        })
        .then((res) => {
          setProgress(100);
          //console.log(res.data.otp);
          toast.success(res.data.message);
          navigat("/login");
          //window.location.href = "http://localhost:3000/forgotpassword";
        })
        .catch((error) => {
          if (error.response) {

            toast.warning(error.response.data.message);

            setProgress(100);
          } else if (error.request) {

            toast.error(error.request);
            setProgress(100);
          } else {

            toast.error(error.message);
            setProgress(100);
          }
        });
    } catch (err) {
      setProgress(100);
      toast.error("Something Wrong");
    }
  };

  return (
    <div className="font-mono bg-bg-white ">
      <LoadingBar
        color="#FF8000"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      <div className="container mx-auto min-h-screen">
        <div className="flex justify-center px-5 my-12">
          <div className="w-full xl:w-3/4 lg:w-11/12 flex shadow-2xl rounded-lg">
            <div className="w-96 h-auto bg-white hidden lg:block lg:w-1/2 bg-cover rounded-l-lg">
              <img src={Img} alt="" />
            </div>
            <div
              className="w-full lg:w-1/2 bg-gray-50 p-3 rounded-lg lg:rounded-l-none"
              style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="px-8 mb-4 text-start">
                <h3 className="pt-4 mb-2 text-2xl">Forgot Your Password?</h3>
                {/* <p className="mb-4 text-sm text-gray-900">
                  We get it, stuff happens. Just enter your mobile number below
                  and we'll send you a OTP to reset your password!
                </p> */}
              </div>
              <div className="px-8 pt-6 pb-8 mb-4 rounded">
                <div className="mb-4">
                  <label
                    className="block mb-2 text-sm font-bold  text-sky-500"
                    htmlFor="email"
                  >
                    Mobile/Email
                  </label>
                  <input
                    onChange={(e) => setUserNumber(e.target.value)}
                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Enter Mobile Number"
                  />
                </div>
                <div className="mb-6 text-center">
                  <button
                    disabled={!userNumber}
                    onClick={forgetPassword}
                    className={`w-full px-4 py-2 font-bold text-white ${!userNumber ? " bg-gray-500" : " bg-gradient-to-r from-[#88C645]  to-[#86cc3c]"}  rounded-lg focus:outline-none focus:shadow-outline`}
                    type="button"
                  >
                    Reset Password
                  </button>
                  {otpin && (
                    <div
                      className="relative z-10"
                      aria-labelledby="modal-title"
                      role="dialog"
                      aria-modal="true"
                    >
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                      <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                              <div className="sm:flex sm:items-start">
                                <div>
                                  <div className="font-semibold text-3xl text-center">
                                    <p>OTP Verification</p>
                                  </div>
                                  <OtpInput
                                    containerStyle=" h-20"
                                    // inputStyle={
                                    //   "text-center outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                                    // }
                                    inputStyle="inputStyle"
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => (
                                      <input {...props} />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                              <button
                                onClick={submitOtp}
                                className="inline-flex w-full mt-2 justify-center rounded-md border-2 border-[#88C645]  text-[#88C645] px-3 py-2 text-sm font-semibold  shadow-sm  sm:ml-3 sm:w-auto"
                              >
                                Submit
                              </button>


                              <button
                                onClick={resendOtp}
                                className="inline-flex w-full mt-2 justify-center rounded-md border-2 border-[#88C645]  text-[#88C645] px-3 py-2 text-sm font-semibold  shadow-sm  sm:ml-3 sm:w-auto"
                              >
                                Resend OTP
                              </button>

                              <button
                                onClick={() => setotpin(!otpin)}
                                type="button"
                                className="inline-flex w-full mt-2 justify-center rounded-md border-2 border-[#e62d0c]  text-[#dd2113] px-3 py-2 text-sm font-semibold  shadow-sm  sm:ml-3 sm:w-auto"
                              >
                                Cancel
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* <div className="relative mb-4 text-left  ">
                  <div className="mb-4">
                    <input
                      onChange={(e) => setOtp(e.target.value)}
                      type="text"
                      className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      placeholder="OTP"
                    />
                  </div>
                </div> */}

                {isOpen && (
                  <div
                    className="relative z-10"
                    aria-labelledby="modal-title"
                    role="dialog"
                    aria-modal="true"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className=" sm:items-start">
                              <form className="mt-6">
                                <div className="relative mb-4 text-left  ">
                                  <div className="mb-4">
                                    <input
                                      onChange={(e) =>
                                        setNewPassword(e.target.value)
                                      }
                                      type={
                                        changePassword ? "password" : "text"
                                      }
                                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                      placeholder="New Password"
                                    />
                                    <span
                                      onClick={() => {
                                        setChangePassword(changeIcon);
                                      }}
                                      className="absolute right-3 top-2 cursor-pointer"
                                    >
                                      {changeIcon ? (
                                        <AiFillEye size={20} />
                                      ) : (
                                        <AiFillEyeInvisible size={20} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="relative mb-4 text-left  ">
                                  <div className="mb-4">
                                    <input
                                      onChange={(e) =>
                                        setConfirmPassword(e.target.value)
                                      }
                                      type={
                                        changePassword ? "password" : "text"
                                      }
                                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                      placeholder="Confirm Password"
                                    />
                                    <span
                                      onClick={() => {
                                        setChangePassword(changeIcon);
                                      }}
                                      className="absolute right-3 top-2 cursor-pointer"
                                    >
                                      {changeIcon ? (
                                        <AiFillEye size={20} />
                                      ) : (
                                        <AiFillEyeInvisible size={20} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <button
                                  onClick={setPasswordFun}
                                  className="inline-block px-6 py-2 border-2 border-[#88C645]  text-[#88C645] font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                                >
                                  Submit
                                </button>
                              </form>
                            </div>
                          </div>
                          <div className="bg-gray-50 px-4 lg:w-fill py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button
                              onClick={() => setIsOpen(!isOpen)}
                              type="button"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <hr className="mb-3 mt-5 border-t" />
                <div className="text-center">
                  <Link
                    to="/signup"
                    className="inline-block text-sm text-sky-500 align-baseline hover:text-sky-800"
                    href="./register.html"
                  >
                    Create an Account!
                  </Link>
                </div>
                <div className="text-center">
                  <Link
                    to="/login"
                    className="inline-block text-sm text-sky-500 align-baseline hover:text-sky-800"
                    href="./index.html"
                  >
                    Already have an account? Login!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassword;
