import React, { useEffect, useState } from "react";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { toast } from "react-custom-alert";
export default function Banner3() {




  const [faqData, setfaqData] = useState([])

  const [url, seturl] = useState()

  useEffect(() => {


    const faq = async () => {
      try {
        await axios(process.env.REACT_APP_API_SUPER_SAVER_BANNER_LIST, {
          method: "GET",
        })
          .then((res) => {

            setfaqData(res.data.banners_list);
            seturl(res.data.baseUrl)

          })
          .catch((error) => {
            if (error.response) {
              toast.error(error.response.data);
            } else if (error.request) {
              toast.error(error.request);
            } else {
              toast.error(error.message);
            }
          });
      } catch (err) {
        toast.error("Something Wrong");
      }
    };

    faq()

  }, [url])




  return (
    <div>
      <p className="text-3xl font-fontHading leading-9 text-left ml-10 text-gray-800 lg:text-4xl mt-0">
        Super Saver Day
      </p>
      <div
        className="
      flex
      justify-center
      items-center
      flex-col
      px-2
      md:px-6
      xl:px-9
      py-3
      md:py-3
      xl:py-1
    "
      >

        <OwlCarousel
          className="owl-theme grid md:grid-cols-2 lg:grid-cols-4 justify-items-between mt-12 gap-y-8 lg:gap-y-0 gap-x-8"
          loop={true}
          margin={20}
          autoplay={true}
          autoplayTimeout={2500}
          autoplaySpeed={2000}

          items={1}

          dots={false}
          responsive={
            {
              0: {
                items: 1
              },
              600: {
                items: 2
              },
              1000: {
                items: 3
              }
            }

          }
        >
          {faqData.map((item) =>
            <div key={item.id} className="item flex items-start flex-col">
              <div className="relative flex justify-center items-center bg-gray-50 py-3 px-3 rounded-md">
                <div>
                  <img className="rounded-md object-fill object-center h-52 w-52" src={url + item.banner_image} alt="mobile" />
                </div>
              </div>
            </div>
          )}

        </OwlCarousel>
      </div>
    </div>
  );
}
